<template>
  <v-container id="investors" fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-account-cash"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $tc("investor", 2) }}</b>
        </h1></template
      >
      <v-btn
        id="btn-add-new-investor"
        v-show="is_admin"
        absolute
        fab
        top
        right
        @click="open_dialog(null)"
        color="primary"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("email") }}</th>
            <th class="text-center">{{ $t("vehicle_title_single") }}</th>
            <th class="text-center">{{ $t("current_position") }}</th>
            <th class="text-center">{{ $t("invested") }}</th>
            <th class="text-center">{{ $t("current_value") }}</th>
            <!-- <th v-show="is_admin" class="text-center">{{ $t("details") }}</th> -->
            <th v-show="is_admin" class="text-center">{{ $t("edit") }}</th>
            <th v-show="is_admin" class="text-center">{{ $t("remove") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(investor, i) in investors" :key="i">
            <td class="text-center">
              {{ $parent.show_string_value(investor.InvestorName) }}
            </td>
            <td class="text-center">{{ investor.InvestorEmail }}</td>
            <td class="text-center" v-if="investor.InvestmentVehicle">
              {{ investor.InvestmentVehicle.Name }}
            </td>
            <td class="text-center" v-else>-</td>
            <td class="text-center">
              {{ investor.StockQuantity.toLocaleString($i18n.locale) }}
            </td>
            <td class="text-center">
              {{
                 formatToCurrency(
                  investor.Currency,
                  investor.TotalInvested
                )
              }}
            </td>
            <td class="text-center">
              {{
                formatToCurrency(
                  investor.Currency,
                  investor.CurrentPosition
                )
              }}
            </td>
            <td v-show="is_admin" class="text-center">
              <v-btn
                class="px-2 ml-1 secondary"
                @click="open_dialog(investor)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-show="is_admin" class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(investor)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-model="dialog_noStocks" v-if="dialog_noStocks">
      <v-container id="add-investor-modal" fluid tag="section">
        <v-card class="dxa_modal">
          <v-card-title class="mb-2">
            <h4 style="text-align: center" class="dxa_modal_title h4">
              {{ $t("have_no_stocks") }}
            </h4>
          </v-card-title>
          <v-card-text>
            <v-card-actions>
              <v-btn
                class="dxa_modal_btnSuccess"
                color="primary"
                min-width="100"
                @click="dialog_noStocks = false"
                >{{ $t("ok") }}</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialog" v-if="dialog" scrollable>
      <DashboardFormsAddInvestorModal
        :investor_prop="editInvestor"
        :company="company"
        @close="reset_dialog"
      ></DashboardFormsAddInvestorModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.InvestorName"
        @close="delete_dialog = false"
        @delete="delete_investor"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DashboardFormsAddInvestorModal from "@/views/pages/company/investors/form/AddInvestorModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import { formatCurrency } from "@/shared/helpers/currencyHelper";

export default {
  name: "DashboardInvestorsTable",
  components: {
    DashboardFormsAddInvestorModal,
    DeleteConfirmationModal,
  },
  async created() {
    this.company_id = this.company.CompanyId;
    this.investors = this.Investors.slice();
    this.check_company_stocks();
  },
  data: () => ({
    rerender: true,
    gs: new GeneralServices(),
    apiService: new ApiService(),
    delete_dialog: false,
    delete_obj: null,
    dialog: false,
    dialog_details: false,
    editInvestor: null,
    image_not_loaded: false,
    investor_selected: null,
    company_id: "",
    dialog_noStocks: false,
    haveNoStocks: false,
    formatToCurrency: formatCurrency,
  }),
  computed: {
    stock_type() {
      return [this.$t("preferred_share"), this.$t("common_share")];
    },
  },
  methods: {
    async check_company_stocks() {
      if (!this.haveNoStocks) {
        await this.apiService
          .getRequest("stock/".concat(this.company.CompanyId))
          .then((resp) => {
            if (resp.message == null || resp.message == "null") {
              this.haveNoStocks = true;
            } else {
            }
          })
          .catch((error) => {});
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    common_share(investor) {
      investor.StocksQuantity.forEach((element) => {
        if (element.StockType == 1 && element.CurrentPosition > 0) {
          return true;
        }
      });
      return false;
    },
    get_stock_type(investor) {
      var res = this.common_share(investor);
      if (res) {
        return this.stock_type[1];
      } else {
        return this.stock_type[0];
      }
    },
    open_dialog: function (investor) {
      this.check_company_stocks();
      if (this.haveNoStocks) {
        this.dialog_noStocks = true;
      } else {
        this.editInvestor = investor;
        this.dialog = true;
      }
    },
    reset_dialog(investment) {
      this.editInvestor = null;
      this.dialog = false;
      if (investment != null) {
        var index = this.investors.map((x) => x.Id).indexOf(investment.Id);
        if (index == -1) {
          this.$emit("reload");
        } else {
          this.investors[index] = investment;
          //this.$emit("update", this.investors);
          this.$emit("reload");
        }
      }
    },
    open_delete_dialog: function (investor) {
      this.delete_obj = investor;
      this.delete_dialog = true;
    },
    async delete_investor(investment) {
      var index = this.investors.indexOf(investment);
      this.investors.splice(index, 1);
      await this.apiService
        .deleteRequest(`investor/delete/${investment.Id}`)
        .then((resp) => {})
        .catch((error) => {});
      this.$emit("reload");
      this.delete_dialog = false;
    },
  },
  props: {
    company: Object,
    Investors: Array,
    is_admin: Boolean,
    can_edit: Boolean,
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  #btn-add-new-investor {
    margin-right: 0px;
  }
}
</style>
