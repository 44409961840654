<template>
  <v-container id="stocks" fluid tag="section" v-if="!historyTable">
    <base-material-card
      color="#7733FF"
      icon="mdi-chart-areaspline"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $t("stocks") }}</b>
        </h1></template
      >
      <v-btn
        v-show="is_admin"
        absolute
        fab
        top
        :loading="loadDownload"
        right
        rounded
        @click="export_stockholders"
        style="margin-right: 143px"
        :style="
          gs.isMobile() ? 'width: 42px;  height: 42px; margin-right: 123px' : ''
        "
        color="secondary"
        min-width="0"
      >
        <v-icon v-if="gs.isMobile()">mdi-download</v-icon>
        <v-icon large v-else>mdi-download</v-icon>
      </v-btn>
      <v-btn
        v-show="is_admin"
        absolute
        fab
        top
        right
        rounded
        style="margin-right: 80px"
        :style="
          gs.isMobile() ? 'width: 42px;  height: 42px; margin-right: 70px' : ''
        "
        @click="historyTable = true"
        color="secondary"
        min-width="0"
      >
        <v-icon v-if="gs.isMobile()">mdi-eye</v-icon>
        <v-icon large v-else>mdi-eye</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="is_admin"
            absolute
            fab
            top
            right
            v-bind="attrs"
            v-on="on"
            color="primary"
            :style="gs.isMobile() ? 'width: 42px;  height: 42px' : ''"
            rounded
          >
            <v-icon v-if="gs.isMobile()">mdi-dots-vertical</v-icon>
            <v-icon large v-else>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="open_dialog(null)">
            <v-list-item-title>{{ $t("add_stock_company") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="open_edit_dialog()">
            <v-list-item-title>{{
              $t("update_stock_value")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("description") }}</th>
            <th class="text-center">{{ $tc("stock", 1) }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(stock, i) in latest_stocks()" :key="i">
            <td class="text-center">{{ stock.StockType.Name }}</td>
            <td class="text-center">{{ stock.StockType.Description }}</td>
            <td class="text-center">R$ {{ stock.Value.toString() }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-model="dialog" v-if="dialog">
      <DashboardFormsAddStockModal
        :company="company"
        :stock="editStock"
        :ExistingStocksIds="latest_stocks().map((x) => x.StockTypeId)"
        @close="reset_dialog"
      ></DashboardFormsAddStockModal>
    </v-dialog>
    <v-dialog v-model="dialogEdit" v-if="dialogEdit">
      <DashboardFormsEditStockModal
        :company="company"
        :stocks="editStock"
        @close="reset_dialog"
      ></DashboardFormsEditStockModal>
    </v-dialog>
  </v-container>
  <StockHistoryTable
    v-else
    :Company="company"
    :CompanyStockHistory="this.company.StockValueHistory"
    @reset="historyTable = false"
    @update="update_stock_history"
  ></StockHistoryTable>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DashboardFormsAddStockModal from "@/views/pages/company/stocks/form/AddStockModal";
import DashboardFormsEditStockModal from "@/views/pages/company/stocks/form/EditStockModal";
import StockHistoryTable from "@/views/pages/company/stocks/table/StockHistoryTable";
import moment from "moment";

export default {
  name: "DashboardStocksTable",
  components: {
    DashboardFormsEditStockModal,
    DashboardFormsAddStockModal,
    StockHistoryTable,
  },
  async created() {
    this.company_id = this.company.CompanyId;
    this.stocks = JSON.parse(JSON.stringify(this.company.StockValueHistory));
  },
  computed: {},
  data: () => ({
    gs: new GeneralServices(),
    rerender: true,
    apiService: new ApiService(),
    add_stock_component: false,
    dialog: false,
    historyTable: null,
    company_id: "",
    editStock: null,
    dialogEdit: false,
    loadDownload: false,
    stocks: [],
  }),
  methods: {
    latest_stocks() {
      return this.stocks.length == 0 ? [] : this.stocks[0].StockTypes;
    },
    export_stockholders() {
      this.loadDownload = true;
      this.apiService
        .getRequest(`company/export-stockholders/${this.company_id}`)
        .then((response) => {
          response.content = "data:application/xlsx;base64," + response.message;
          const link = document.createElement("a");
          link.href = response.content;
          link.setAttribute(
            "download",
            `StockHolders- ${moment().format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.loadDownload = false;
        })
        .catch((error) => {
          this.loadDownload = false;

          return error;
        });
    },
    open_dialog: function (stock) {
      this.dialog = true;
    },
    open_edit_dialog() {
      this.editStock = this.latest_stocks();
      this.dialogEdit = true;
    },
    compare(a, b) {
      if (moment(a.Date) < moment(b.Date)) {
        return -1;
      }
      if (moment(a.Date) > moment(b.Date)) {
        return 1;
      }
      return 0;
    },
    update_stock_history(stocks) {
      this.stocks = stocks;
      this.$emit("update", this.stocks);
    },
    reset_dialog(history) {
      this.dialogEdit = false;
      this.dialog = false;

      if (history != null) {
        var index = this.stocks.map((x) => x.Id).indexOf(history.Id);
        if (index == -1) {
          this.stocks.push(history);
          this.stocks.sort(this.compare);
        } else {
          this.stocks[index] = history;
        }
        this.$emit("update", this.stocks);
      }
    },
  },
  props: {
    company: Object,
    is_admin: Boolean,
    can_edit: Boolean,
  },
};
</script>
