import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"justify-content":"center !important","display":"flex !important"},attrs:{"id":"add-stock-modal","fluid":"","tag":"section"}},[_c(VCard,{staticClass:"dxa_modal"},[_c(VCardTitle,{staticClass:"mb-6"},[_c('h4',{staticClass:"dxa_modal_title h4"},[_vm._v(_vm._s(_vm.$t("stocks")))])]),_c(VCardText,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit()}}},[_c(VSelect,{attrs:{"items":_vm.stocks,"item-value":"Id","item-text":"Name","label":_vm.$t('stocks')},model:{value:(_vm.stock_selected),callback:function ($$v) {_vm.stock_selected=$$v},expression:"stock_selected"}}),_c(VTextField,{staticClass:"pt-0",attrs:{"label":_vm.$tc('stock', 1),"type":"number"},model:{value:(_vm.stock_value),callback:function ($$v) {_vm.stock_value=$$v},expression:"stock_value"}}),_c(VCardActions,{staticClass:"pl-0 dxa_modal_actions"},[_c(VBtn,{staticClass:"dxa_modal_btnSuccess",attrs:{"color":"primary","min-width":"100","loading":_vm.loading,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("save")))]),_c(VBtn,{staticClass:"dxa_modal_btnError",attrs:{"color":"red","min-width":"100"},on:{"click":function($event){return _vm.$emit('close', null)}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1),(_vm.alert_msg != null)?_c(VAlert,{staticClass:"ma-2",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.alert_msg))+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }