<template>
  <v-container id="kpi-table" fluid tag="section" v-if="!selectedKpi">
    <base-material-card
      color="#7733FF"
      icon="mdi-bullseye-arrow"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $tc("kpi", 2) }}</b>
        </h1></template
      >
      <v-btn
        v-show="is_admin"
        absolute
        fab
        top
        right
        color="primary"
        @click="open_dialog()"
        data-test="Company:Kpi:Add:Button"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("name_pt") }}</th>
            <th class="text-center">{{ $t("name_en") }}</th>
            <th class="text-center">{{ $t("description") }}</th>
            <th class="text-center">{{ $t("current_value") }}</th>
            <th class="text-center">{{ $t("date") }}</th>
            <th v-show="is_admin" class="text-center">{{ $t("options") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(kpi, i) in kpis" :key="i">
            <td class="text-center">{{ kpi.Name }}</td>
            <td class="text-center">{{ getKpiReportEnName(kpi) }}</td>
            <td class="text-center">{{ kpi.Description }}</td>
            <td class="text-center">
              {{
                kpi.KpiValueHistory.length > 0
                  ? kpi.KpiValueHistory[kpi.KpiValueHistory.length - 1].Value
                  : 0
              }}
            </td>
            <td class="text-center">
              {{
                get_date_formated(
                  kpi.KpiValueHistory.length > 0
                    ? kpi.KpiValueHistory[kpi.KpiValueHistory.length - 1].Date
                    : null
                )
              }}
            </td>
            <td v-show="is_admin" class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="selectedKpi = kpi"
                color="secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                @click="open_edit_value_dialog(kpi)"
                color="secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                @click="open_edit_dialog(kpi)"
                color="secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(kpi)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-model="dialog" v-if="dialog" scrollable>
      <DashboardFormsAddKpiModal
        :company="company"
        :Indexes="indexes_list"
        :Kpi="editKpi"
        @close="reset_dialog"
      ></DashboardFormsAddKpiModal>
    </v-dialog>
    <v-dialog v-model="dialogEdit" v-if="dialogEdit">
      <DashboardFormsEditKpiModal
        :company="company"
        :kpi="editKpiValue"
        @close="reset_dialogEdit"
      ></DashboardFormsEditKpiModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name"
        @close="delete_dialog = false"
        @delete="delete_kpi"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <KpiHistoryTable
    v-else
    :Kpi="selectedKpi"
    @reset="reset_selected_kpi"
  ></KpiHistoryTable>
</template>

<script>
import ApiService from "@/services/ApiService";
import DashboardFormsAddKpiModal from "@/views/pages/company/kpis/form/AddKpiModal";
import DashboardFormsEditKpiModal from "@/views/pages/company/kpis/form/EditKpiModal";
import KpiHistoryTable from "@/views/pages/company/kpis/table/KpiHistoryTable";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";

export default {
  name: "DashboardKpiTable",
  components: {
    KpiHistoryTable,
    DashboardFormsAddKpiModal,
    DashboardFormsEditKpiModal,
    DeleteConfirmationModal,
  },
  async created() {
    this.company_id = this.company.CompanyId;
  },
  data: () => ({
    rerender: true,
    apiService: new ApiService(),
    delete_dialog: false,
    delete_obj: null,
    dialog: false,
    selectedKpi: null,
    company_id: "",
    editKpiValue: null,
    editKpi: null,
    dialogEdit: false,
  }),
  computed: {
    indexes_list() {
      var list = [];
      for (var i = 1; i <= this.kpis.length; i++) {
        list.push(i);
      }
      if (this.editKpi == null) {
        list.push(this.kpis.length + 1);
      }
      return list;
    },
  },
  methods: {
    getKpiReportEnName(kpi) {
      const parsedKpi = JSON.parse(kpi.ReportName);
      if (parsedKpi.en !== "") {
        return parsedKpi.en;
      }
    },
    get_date_formated: function (date) {
      if (date == null) {
        return moment().format("DD/MM/YYYY");
      } else {
        return moment(date).format("MM/DD/YYYY");
      }
    },
    open_dialog: function () {
      this.dialog = true;
    },
    open_edit_value_dialog(kpi) {
      this.editKpiValue = kpi;
      this.dialogEdit = true;
    },
    open_edit_dialog(kpi) {
      this.editKpi = kpi;
      this.dialog = true;
    },
    reset_selected_kpi() {
      this.selectedKpi = null;
    },
    reset_dialogEdit(kpis) {
      this.dialogEdit = false;
      if (kpis != null) {
        this.$parent.company.CompanyKpis = kpis;
        this.$parent.reload_company_kpis();
      }
    },
    reset_dialog(kpis) {
      this.dialog = false;
      this.editKpi = null;
      if (kpis != null) {
        this.$parent.company.CompanyKpis = kpis;
        this.$parent.reload_company_kpis();
      }
    },
    open_delete_dialog: function (kpi) {
      this.delete_obj = kpi;
      this.delete_dialog = true;
    },
    delete_kpi: async function (kpi) {
      await this.apiService
        .deleteRequest("kpi/delete", kpi)
        .then((resp) => {
          this.$emit("reload");
          this.delete_dialog = false;
        })
        .catch((error) => {});
    },
  },
  props: {
    company: Object,
    kpis: Array,
    is_admin: Boolean,
    can_edit: Boolean,
  },
};
</script>
