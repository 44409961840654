<template>
  <v-container id="edit-kpi-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">{{ kpi.Name }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('value_change_date')"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            type="number"
            v-model="value"
            :label="$t('current_value')"
            class="pt-0"
          />

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-alert class="ma-2" v-if="error" type="error">
          {{ $t(error) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "DashboardFormsEditKpiModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    error: null,
    value: null,
    date: new Date().toISOString().substr(0, 10),
    menu2: false,
  }),
  props: {
    kpi: Object,
    company: Object,
  },
  methods: {
    submit: async function () {
      if (this.loading) {
        return;
      }
      this.error = null;
      this.loading = true;
      this.value = parseFloat(this.value);

      await this.apiService
        .postRequest("kpi/value", {
          ObjId: this.kpi.Id,
          Value: this.value,
          Date: this.date,
        })
        .then((result) => {
          this.$emit("close", JSON.parse(result.message));
        })
        .catch((error) => {
          this.error = error.body.message;
        });
      this.loading = false;
    },
  },
};
</script>
