<template>
  <v-container id="edit-kpi-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">{{ $t("edit_kpi_history") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form">
          <v-text-field
            v-model="History.Date"
            :label="$t('date')"
            readonly
            disabled
          ></v-text-field>

          <v-text-field
            type="number"
            v-model="value"
            :label="$t('current_value')"
            class="pt-0"
          />

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
        <v-alert class="ma-2" v-if="error" type="error">
          {{ $t(error) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "EditKpiHistoryModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    error: null,
    value: null,
    date: new Date().toISOString().substr(0, 10),
    menu2: false,
  }),
  props: {
    History: Object,
  },
  async created() {
    this.value = this.History.Value;
  },
  methods: {
    get_date_formated: function (date) {
      date = new Date(date.replace("T", " "));
      var day = date.getUTCDate().toString().padStart(2, "0");
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    submit: async function () {
      if (this.loading) {
        return;
      }
      this.error = null;
      this.loading = true;
      this.value = parseFloat(this.value);

      await this.apiService
        .putRequest("kpi/history/edit", {
          Id: this.History.Id,
          KpiId: this.History.KpiId,
          Date: this.History.Date,
          Value: this.value,
        })
        .then((result) => {
          this.History.Value = this.value;
          this.$emit("close", null);
        })
        .catch((error) => {
          this.error = error.body.message;
        });
      this.loading = false;
    },
  },
};
</script>
