<template>
  <v-container id="kpi-table" fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-chart-box"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $t("stock_history") }}</b>
        </h1></template
      >
      <v-btn
        absolute
        fab
        top
        right
        color="secondary"
        @click="$emit('reset')"
        rounded
      >
        <v-icon x-large> mdi-arrow-left-bold </v-icon>
      </v-btn>
      <!-- <v-btn
        v-show="is_admin"
        absolute
        fab
        top
        right
        color="primary"
        @click="open_dialog()"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn> -->
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("date") }}</th>
            <th class="text-center">{{ $t("current_value") }}</th>
            <th class="text-center">{{ $t("edit") }}</th>
            <th class="text-center">{{ $t("remove") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(history, i) in stocks" :key="-i">
            <td class="text-center">
              {{ gs.convert_date(history.Date, false) }}
            </td>
            <td class="text-center pt-5 pb-5">
              <v-row
                justify="center"
                v-for="(st, i) in history.StockTypes"
                :key="i"
              >
                {{ st.StockType.Name }}:
                <b style="font-weight: bold; margin-left: 4px">{{
                  gs.format_to_currency(st.Value)
                }}</b>
              </v-row>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_edit_dialog(i)"
                color="secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(history)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-model="dialogEdit" v-if="dialogEdit">
      <DashboardFormsEditStockModal
        :company="Company"
        :stocks="editStock"
        :HistoryDate="editDate"
        @close="reset_dialog"
      ></DashboardFormsEditStockModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="$t('this_date')"
        @close="delete_dialog = false"
        @delete="delete_history"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DashboardFormsEditStockModal from "@/views/pages/company/stocks/form/EditStockModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";

export default {
  name: "StocksHistoryTable",
  components: {
    DashboardFormsEditStockModal,
    DeleteConfirmationModal,
  },
  async created() {
    this.stocks = JSON.parse(JSON.stringify(this.CompanyStockHistory));
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    delete_dialog: false,
    delete_obj: null,
    dialog: false,
    editDate: null,
    stocks: [],
    company_id: "",
    editStock: null,
    dialogEdit: false,
  }),
  props: {
    Company: Object,
    CompanyStockHistory: Array,
  },
  methods: {
    open_edit_dialog(i) {
      this.editStock = this.stocks[i].StockTypes;
      this.editDate = this.stocks[i].Date;
      this.dialogEdit = true;
    },
    close_dialog() {
      this.dialogEdit = false;
      this.editStock = null;
      this.editDate = null;
    },
    compare(a, b) {
      if (moment(a.Date) < moment(b.Date)) {
        return -1;
      }
      if (moment(a.Date) > moment(b.Date)) {
        return 1;
      }
      return 0;
    },
    reset_dialog(history) {
      this.close_dialog();
      if (history != null) {
        var index = this.stocks.map((x) => x.Id).indexOf(history.Id);
        if (index == -1) {
          this.stocks.push(history);
          this.stocks.sort(this.compare);
        } else {
          this.stocks[index] = history;
        }
        this.$emit("update", this.stocks);
      }
    },
    open_delete_dialog: function (stocks) {
      this.delete_obj = stocks;
      this.delete_dialog = true;
    },
    async delete_history(history) {
      var index = this.stocks.indexOf(history);
      this.stocks.splice(index, 1);
      this.delete_dialog = false;
      await this.apiService
        .deleteRequest(`stock/history/delete/${history.Id}`)
        .then((resp) => {
          this.$emit("update", this.stocks);
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.flashMessage.error({ title: this.$t(error.body.message) });
        });
    },
  },
};
</script>
