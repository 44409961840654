<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-6">
      <h4 class="dxa_modal_title h4">
        {{ edit ? $t("edit_kpi") : $t("add_kpi") }}
      </h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="submit()" ref="form">
        <v-text-field
          v-model="kpi.Name"
          :label="$t('name_pt')"
          class="pt-0"
          data-test="Company:Kpi:Add:Name"
        />
        <v-text-field
          v-model="nameEn"
          :label="$t('name_en')"
          class="pt-0"
          data-test="Company:Kpi:Add:NameEn"
        />

        <v-text-field
          v-model="kpi.Description"
          :label="$t('description')"
          class="pt-0"
          data-test="Company:Kpi:Add:Description"
        />

        <v-text-field
          data-test="Company:Kpi:Add:Unit"
          v-model="kpi.Unit"
          :label="$t('unit')"
          class="pt-0"
        />

        <v-select
          v-model="kpi.Index"
          :items="Indexes"
          :label="$t('index')"
          class="pt-0"
          data-test="Company:Kpi:Add:Index"
        />
        <v-select
          :label="$t('select_graph_type')"
          dense
          v-model="kpi.DefaultGraph"
          :items="selectedChartType"
          :item-text="translateDefaultGraph"
          item-value="value"
        ></v-select>

        <v-text-field
          :label="$t('x_axis_start')"
          v-model="x_start"
          v-mask="['##/####']"
          :rules="[vs.validDate(x_start, false)]"
          @blur="
            kpi.XAxisStart = gs.parse_to_datetime_string(x_start, 'MM/YYYY')
          "
          class="pt-0"
        />

        <v-text-field
          :label="$t('x_axis_end')"
          v-model="x_end"
          v-mask="['##/####']"
          :rules="[vs.validDate(x_end, false)]"
          @blur="kpi.XAxisEnd = gs.parse_to_datetime_string(x_end, 'MM/YYYY')"
          class="pt-0"
        />

        <v-text-field
          v-model.number="kpi.YAxisStart"
          :label="$t('y_axis_start')"
          type="number"
          @change="
            kpi.YAxisStart = kpi.YAxisStart === '' ? null : kpi.YAxisStart
          "
          class="pt-0"
        />

        <v-text-field
          v-model.number="kpi.YAxisEnd"
          :label="$t('y_axis_end')"
          type="number"
          @change="kpi.YAxisEnd = kpi.YAxisEnd === '' ? null : kpi.YAxisEnd"
          class="pt-0"
        />

        <!-- <v-text-field
            type="number"
            v-model="kpi.Goal"
            :label="$t('goal')"
            @change="kpi.Goal = parseFloat(kpi.Goal)"
            class="pt-0"
          /> -->

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close', null)"
            >{{ $t("cancel") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-alert class="ma-2" v-if="error" type="error">
        {{ $t(error) }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import ValidationService from "@/services/ValidationService";
import moment from "moment";
export default {
  name: "DashboardFormsAddKpiModal",
  data: () => ({
    vs: new ValidationService(),
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    error: null,
    selectedChartType: [
      { text: "Bar-Graphic", value: 0 },
      { text: "Line-Graphic", value: 1 },
    ],
    nameEn: null,
    kpi: {
      Name: null,
      CompanyId: null,
      Description: null,
      Index: null,
      DefaultGraph: 0,
      XAxisStart: null,
      XAxisEnd: null,
      YAxisStart: null,
      YAxisEnd: null,
      Unit: null,
      // Goal: null,
    },
    x_start: null,
    x_end: null,
    edit: false,
  }),
  props: {
    company: Object,
    Kpi: Object,
    Indexes: Array,
  },
  async created() {
    if (this.Kpi != null) {
      this.edit = true;
      this.kpi = {
        Id: this.Kpi.Id,
        Name: this.Kpi.Name,
        CompanyId: this.company.CompanyId,
        Description: this.Kpi.Description,
        Index: this.Kpi.Index,
        DefaultGraph: this.Kpi.DefaultGraph,
        XAxisStart: this.Kpi.XAxisStart,
        XAxisEnd: this.Kpi.XAxisEnd,
        YAxisStart: this.Kpi.YAxisStart,
        YAxisEnd: this.Kpi.YAxisEnd,
        Unit: this.Kpi.Unit,
      };
      const parsedReportName = JSON.parse(this.Kpi.ReportName);
      if (parsedReportName.en !== "") {
        this.nameEn = parsedReportName.en;
      }
      if (this.kpi.XAxisStart) {
        this.x_start = moment(this.kpi.XAxisStart).format("MM/YYYY");
      }
      if (this.kpi.XAxisEnd) {
        this.x_end = moment(this.kpi.XAxisEnd).format("MM/YYYY");
      }
    } else {
      this.edit = false;
      this.kpi = {
        Name: null,
        CompanyId: this.company.CompanyId,
        Description: null,
        Index: this.Indexes[this.Indexes.length - 1],
        Unit: null,
        XAxisStart: null,
        XAxisEnd: null,
        YAxisStart: null,
        YAxisEnd: null,
        DefaultGraph: 0,
      };
    }
  },
  methods: {
    translateDefaultGraph(item) {
      return this.$t(item.text);
    },
    submit: async function () {
      if (this.loading) {
        return;
      }

      this.kpi = {
        ...this.kpi,
        ReportName: JSON.stringify({ pt: this.kpi.Name, en: this.nameEn }),
      };

      this.error = null;
      this.loading = true;
      if (this.kpi.Id) {
        await this.apiService
          .putRequest(`kpi/edit`, this.kpi)
          .then((result) => {
            this.$emit("close", JSON.parse(result.message));
          });
      } else {
        await this.apiService
          .postRequest(`kpi/add`, this.kpi)
          .then((result) => {
            this.$emit("close", JSON.parse(result.message));
          });
      }
      this.loading = false;
    },
  },
};
</script>
