<template>
  <v-container solid color="white" fluid tag="section" id="companyPage">
    <CompanyAppBar
      :TabOptions="tab_options"
      :admin_type="admin_type"
      :company_name="company.Name"
      :UserType="userType"
    ></CompanyAppBar>
    <div style="margin-top: 13vh">
      <div v-if="selected_index === 0">
        <v-row justify="center" v-if="companyLoading">
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <CompanyDetails
          v-else
          :UserTotalInvested="
            gs.format_to_currency(get_total_invested(user_investment))
          "
          :InvestorsLoading="investorsLoading"
          :FilesLoading="filesLoading"
          :StockHistoryLoading="stockHistoryLoading"
          :company="company"
          :UserType="userType"
          :admin_type="admin_type"
          :can_edit="can_edit"
          :investors="company.Investors"
          :files="company.Files"
        ></CompanyDetails>
      </div>
      <div v-if="selected_index === 1">
        <v-row
          justify="center"
          v-if="companyLoading || investorsLoading || transactionsLoading"
        >
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <DashboardInvestorsTable
          v-else-if="company.Investors && !investorsLoading"
          :company="company"
          :Investors="company.Investors"
          :is_admin="userType == userTypeEnum.Admin"
          :can_edit="can_edit"
          @update="
            (value) => {
              company.Investors = investors;
            }
          "
          @reload="investments_changed()"
        ></DashboardInvestorsTable>
      </div>
      <div v-if="selected_index === 2 && userType === userTypeEnum.Investor">
        <v-row justify="center" v-if="companyLoading">
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <DashboardReportTable
          v-else-if="userType < 2"
          :companyId="company_id"
          :userId="userId"
        />
      </div>
      <div v-if="selected_index === 3">
        <v-row justify="center" v-if="filesLoading">
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <DashboardFilesTable
          v-else
          :company_id="company_id"
          :files_prop="company.Files"
          :is_admin="userType == userTypeEnum.Admin"
        ></DashboardFilesTable>
      </div>
      <div v-if="selected_index === 4">
        <v-row justify="center" v-if="transactionsLoading">
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <DashboardTransactionsTable
          v-else
          :company="company"
          :transactions="transactions"
          :investors="company.Investors"
          :is_admin="userType == userTypeEnum.Admin"
          :can_edit="can_edit"
          @loadingTransaction="reload_transactions"
        ></DashboardTransactionsTable>
      </div>
      <div v-if="selected_index === 5">
        <v-row justify="center" v-if="stockHistoryLoading">
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <DashboardStocksTable
          v-else
          :company="company"
          :is_admin="userType == userTypeEnum.Admin"
          :can_edit="can_edit"
          @update="updateStocks"
        ></DashboardStocksTable>
      </div>
      <div v-if="selected_index === 7">
        <v-row justify="center" v-if="kpisLoading">
          <v-progress-circular
            indeterminate
            size="15"
            color="primary"
          ></v-progress-circular>
        </v-row>
        <DashboardKpiTable
          v-else
          :company="company"
          :kpis="company.CompanyKpis"
          :is_admin="
            userType == userTypeEnum.Admin ||
            userType == userTypeEnum.ContentAnalyst
          "
          :can_edit="can_edit"
          @reload="reload_company_kpis"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import CompanyAppBar from "./AppBar";
import DashboardFilesTable from "./files/table/Files";
import DashboardInvestorsTable from "./investors/table/Investors";
import DashboardTransactionsTable from "./transactions/table/TransactionsTable";
import DashboardStocksTable from "./stocks/table/Stocks";
import CompanyDetails from "./details/index/CompanyDetails";
import DashboardKpiTable from "./kpis/table/KpiTable";
import DashboardReportTable from "@/components/dxa/Extract/ExtractTableComponent";
import moment from "moment";
import { UserTypeEnum } from "@/shared/enums/UserType";
import mixpanel from "mixpanel-browser";

export default {
  name: "CompanyPage",
  components: {
    CompanyAppBar,
    DashboardReportTable,
    DashboardFilesTable,
    DashboardInvestorsTable,
    DashboardTransactionsTable,
    CompanyDetails,
    DashboardStocksTable,
    DashboardKpiTable,
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    selected_index: 0,
    company_id: "",
    company: {},
    investors: [],
    files: [],
    admin_type: null,
    user_investment: {},
    video: "",
    transactions: [],
    stock_chart: {
      labels: [],
      datasets: {},
    },
    companyLoading: true,
    transactionsLoading: true,
    eventsLoading: true,
    stockHistoryLoading: true,
    investorsLoading: false,
    filesLoading: true,
    kpisLoading: true,
    userType: UserTypeEnum.Investor,
    can_edit: false,
    userTypeEnum: UserTypeEnum,
    userId: null,
    userProfileData: null,
  }),
  computed: {
    tab_options: function () {
      var list = [];
      var options = [
        {
          title: this.$t("overall"),
          index: 0,
          requiresAdmin: false,
        },
        {
          title: this.$t("stakeholders_list"),
          index: 1,
          requiresAdmin: true,
          adminRequired: 0,
        },
        {
          title: this.$t("data_room"),
          index: 3,
          requiresAdmin: false,
        },
        {
          title: this.$tc("transaction", 2),
          index: 4,
          requiresAdmin: true,
        },
      ];
      options.forEach((element) => {
        if (
          element.requiresAdmin &&
          this.userType === this.userTypeEnum.Admin &&
          element.adminRequired == this.admin_type
        ) {
          list.push(element);
        } else if (
          ((element.requiresAdmin &&
            this.userType == this.userTypeEnum.Admin) ||
            !element.requiresAdmin) &&
          element.adminRequired == null
        ) {
          list.push(element);
        } else if (
          element.requiresInvestor != null &&
          this.userType == this.userTypeEnum.Investor
        ) {
          list.push(element);
        }
        // else if (
        //   element.adminRequired != null &&
        //   element.adminRequired == this.admin_type
        // ) {
        //   list.push(element);
        // }
      });

      if (
        this.userType === this.userTypeEnum.Investor &&
        process.env.VUE_APP_EXTRACTS === "ON"
      ) {
        list.push({
          title: this.$tc("extract", 2),
          index: 2,
          requiresAdmin: false,
          requiresInvestor: true,
        });
      }

      if (
        this.userType === this.userTypeEnum.Admin ||
        this.userType === this.userTypeEnum.ContentAnalyst
      ) {
        list.push({
          title: this.$tc("kpi", 2),
          index: 7,
          is_admin: true,
          requiresAdmin: false,
        });
      }

      return list;
    },
  },
  async created() {
    this.redirectChooseExtract();
    var user = JSON.parse(localStorage.getItem("user"));
    this.userId = user.id;
    this.admin_type = user.adminType;
    this.userType = user.type;
    if (user.adminType == 0) {
      this.admin_type == 0;
      this.can_edit = true;
    } else {
      this.admin_type == 1;
    }
    this.transactions_loading = true;
    this.company_id = parseInt(this.$route.params.company_id);
    var tab = this.$route.query.tab;
    if (
      tab &&
      this.tab_options.map((x) => x.index).indexOf(parseInt(tab) - 1) >= 0
    ) {
      this.selected_index = parseInt(tab) - 1;
    } else {
      this.$router.push({ query: { tab: 1 } });
    }
  },
  async mounted() {
    await this.getUserProfile();
    await this.reload_company();
    await this.reload_company_files();
    await this.reload_company_stock_history();
    await this.reload_company_events();
    await this.reload_company_kpis();
    await this.reload_transactions();
    const userType = this.userProfileData.type;

    if (userType === this.userTypeEnum.Investor) {
      await this.get_user_investment();
    }

    if (userType === this.userTypeEnum.Admin) {
      await this.reload_investors();
    }

    if (userType === this.userTypeEnum.Banker) {
      mixpanel.track("BankerMonitoringPage_View", {
        partner_id: this.userProfileData.partner.id,
        user_id: this.userProfileData.id,
        user_type: "banker",
        company_name: this.company.Name,
        num_of_allocations: this.company.TotalAllocations,
      });
    }
  },
  methods: {
    async getUserProfile() {
      this.loading = true;
      await this.apiService
        .getRequest("user/user-profile")
        .then((resp) => {
          this.userProfileData = resp.content;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    isMobile() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return true;
      }
      return false;
    },
    async investments_changed() {
      await this.reload_investors();
      await this.reload_transactions();
    },
    get_total_invested(investor) {
      var total = 0;
      if (investor.Id != undefined) {
        investor.Transactions.forEach((element) => {
          total =
            element.Action === 0
              ? total + element.TransactionValue
              : total - element.TransactionValue;
        });
      }

      return total;
    },
    async get_user_investment() {
      this.investors_loading = true;
      await this.apiService
        .getRequest(`investor/${this.company_id}/investor`)
        .then((resp) => {
          this.user_investment = JSON.parse(resp);
          this.investors_loading = false;
        })
        .catch((error) => {
          this.investors_loading = false;
        });
    },
    get_current_position(investor) {
      var total = 0;
      this.transactions
        .filter((x) => x.investmentId == investor.Id)
        .forEach((element) => {
          total =
            element.action === 0
              ? total + element.stockQuantity
              : total - element.stockQuantity;
        });
      return total;
    },
    show_string_value(value) {
      if (value == null) {
        return "";
      } else {
        return value;
      }
    },
    get_investor_email: function (investor) {
      if (investor == null) {
        return "";
      } else {
        return this.show_string_value(investor.Email);
      }
    },
    async reload_company() {
      this.companyLoading = true;
      await this.apiService
        .getRequest("company/info/".concat(this.company_id))
        .then((resp) => {
          this.company = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.companyLoading = false;
    },
    async reload_company_files() {
      this.filesLoading = true;
      await this.apiService
        .getRequest(`companyfile/list/details/${this.company_id}`)
        .then((resp) => {
          this.company.Files = JSON.parse(resp.message);
        });
      this.filesLoading = false;
    },
    async reload_company_stock_history() {
      this.stockHistoryLoading = true;
      await this.apiService
        .getRequest("stock/history/".concat(this.company_id))
        .then((resp) => {
          this.company.StockValueHistory = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.stockHistoryLoading = false;
    },
    async reload_company_events() {
      this.eventsLoading = true;
      await this.apiService
        .getRequest("companyevent/list/".concat(this.company_id))
        .then((resp) => {
          this.company.CompanyEvents = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.eventsLoading = false;
    },
    async reload_company_kpis() {
      this.kpisLoading = true;
      await this.apiService
        .getRequest("kpi/list/".concat(this.company_id))
        .then((resp) => {
          this.company.CompanyKpis = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.kpisLoading = false;
    },
    async reload_investors() {
      this.investorsLoading = true;
      await this.apiService
        .getRequest(`investment/${this.company_id}/investments`)
        .then((resp) => {
          this.company.Investors = JSON.parse(resp.message);
        })
        .catch((error) => {});
      this.investorsLoading = false;
    },
    async reload_transactions() {
      if (this.userType != this.userTypeEnum.Admin) return;

      this.transactionsLoading = true;
      await this.apiService
        .getRequest(`transaction/${this.company_id}`)
        .then((resp) => {
          this.transactions = resp.content;
        })
        .catch((error) => {});
      this.transactionsLoading = false;
    },
    // ate voltar com o componente de extrato
    // ele vai receber um redirect toda vez que fizer a busca pela url
    redirectChooseExtract() {
      const query = this.$route.query.tab;

      if (query === "3") {
        this.$router.push({ query: { tab: 1 } });
      }
    },
    updateStocks(value) {
      this.company.StockValueHistory = value;
      this.reload_company_stock_history();
    },
  },
};
</script>
