<template>
  <v-container id="add-file-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title>
        <h4 class="dxa_modal_title h4">{{ $t("new_file") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form" class="mt-5">
          <v-select
            :items="index_options"
            :label="$t('index')"
            v-model="selected_index"
            outlined
          ></v-select>
          <input
            v-if="!edit"
            @change="files_changed"
            type="file"
            id="file"
            ref="file"
            prefix
            label="File input"
          />

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close')"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "DashboardFormsAddFileModal",
  props: {
    company_id: Number,
    index_options: Array,
    file: Object,
  },

  data: () => {
    return {
      apiService: null,
      loading: false,
      edit: false,
      selected_file: null,
      selected_index: null,
    };
  },
  created() {
    this.apiService = new ApiService();
    if (this.file != null) {
      this.edit = true;
      this.selected_file = this.file;
      this.selected_index = this.file.Index;
    } else {
      this.selected_index = this.index_options[this.index_options.length - 1];
    }
  },
  methods: {
    files_changed(event) {
      this.selected_file = event.target.files[0];
    },
    submit: async function () {
      if (this.loading) {
        return;
      }
      this.error = null;
      this.loading = true;

      let langId = -1;
      if (this.$i18n.locale === "pt") {
        langId = 0;
      } else if (this.$i18n.locale === "en") {
        langId = 1;
      } else {
        langId = 2;
      }

      var fd = new FormData();
      fd.append("file", this.selected_file);

      if (this.edit) {
        await this.apiService
          .putRequest("companyfile/edit/details", {
            File: this.file,
            Index: this.selected_index,
          })
          .then((result) => {
            var objs = JSON.parse(result.message);
            this.$emit("reset", objs);
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        await this.apiService
          .postRequest(
            `companyfile/add/details/${this.company_id}/${langId}/${this.selected_index}`,
            fd
          )
          .then((result) => {
            var objs = JSON.parse(result.message);
            this.$emit("reset", objs);
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.loading = false;
    },
  },
};
</script>
