<template>
  <v-container id="add-stock-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">
          {{ $t("edit_stocks") }}
        </h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('value_change_date')"
                readonly
                :disabled="HistoryDate != null"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @change="date_changed"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-for="(stock, i) in selected_date_stocks"
            :key="i"
            type="number"
            min="0"
            v-model="stock.Value"
            :label="$t('single_stock') + ' ' + stock.StockType.Name"
            class="pt-0"
          />

          <div class="text-center my-4" v-if="selected_date_stocks.length == 0">
            <span>
              {{ $t("no_stocks") }}
            </span>
          </div>

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-alert class="ma-2" v-if="alert_msg != null" type="error">
          {{ $t(alert_msg) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";
export default {
  name: "DashboardFormsEditStockModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    stock_value: 0,
    selected_date_stocks: [],
    alert_msg: null,
    date: new Date().toISOString().substr(0, 10),
    today: new Date().toISOString().substr(0, 10),
    menu2: false,
  }),
  props: {
    HistoryDate: String,
    stocks: Array,
    company: Object,
  },
  async created() {
    this.selected_date_stocks = JSON.parse(JSON.stringify(this.stocks));
    if (this.HistoryDate != null) {
      this.date = this.HistoryDate.split("T")[0];
    }
  },
  async mounted() {},
  methods: {
    date_changed: function () {
      if (this.date == this.today) {
        this.selected_date_stocks = this.stocks;
      }
      this.selected_date_stocks = this.company.StockValueHistory.filter(
        (x) => x.Date.split("T")[0] == this.date
      );
      if (this.selected_date_stocks.length == 0) {
        this.selected_date_stocks = [];
        return;
      }
      this.selected_date_stocks = this.selected_date_stocks[0].StockTypes;
    },
    submit: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.alert_msg = null;
      let stockHistory_send = {
        Stocks: this.selected_date_stocks.map((x) => {
          x.Value = parseFloat(x.Value);
          return x;
        }),
        CompanyId: this.company.CompanyId,
        Data: this.date,
      };

      this.apiService
        .putRequest(`stock/edit`, stockHistory_send)
        .then((result) => {
          this.loading = false;
          this.$emit("close", JSON.parse(result.message));
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          this.alert_msg = this.$t(error.body.message);
          this.loading = false;
        });
    },
  },
};
</script>
