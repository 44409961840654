<template>
  <v-container id="transactions" fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-cash-multiple"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $tc("transaction", 2) }}</b>
        </h1></template
      >
      <v-btn
        id="btn-add-new-transaction"
        data-test="Vehicle:TransactionsTable:BtnNewTransaction"
        v-show="is_admin"
        absolute
        fab
        top
        right
        @click="open_dialog(null)"
        color="primary"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("operation") }}</th>
            <th class="text-center">{{ $t("vehicle_title_single") }}</th>
            <th class="text-center">{{ $t("single_round") }}</th>
            <th class="text-center">{{ $t("email") }}</th>
            <th class="text-center">{{ $t("stock_quantity") }}</th>
            <th class="text-center">{{ $t("stock_price") }}</th>
            <th class="text-center">{{ $tc("stock", 2) }}</th>
            <th class="text-center">{{ $t("date") }}</th>
            <th v-show="is_admin" class="text-center">{{ $t("options") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(t, i) in transactions" :key="i">
            <td class="text-center">
              {{ $parent.show_string_value(t.investment.investorName) }}
            </td>
            <td class="text-center">
              {{
                t.action === 0
                  ? $tc("stock_operation", 1)
                  : $tc("stock_operation", 2)
              }}
            </td>
            <td class="text-center" v-if="t.investment.investmentVehicle">
              {{ t.investment.investmentVehicle.name }}
            </td>
            <td class="text-center" v-else>-</td>
            <td class="text-center" v-if="t.investmentRoundId">
              {{ t.investmentRoundId }}
            </td>
            <td class="text-center" v-else>-</td>
            <td class="text-center">{{ t.investment.investorEmail }}</td>
            <td class="text-center">{{ t.stockQuantity }}</td>
            <td class="text-center">
              {{ formatToCurrency(t.investment.currency, t.stockValue) }}
            </td>
            <td class="text-center">
              {{ formatToCurrency(t.investment.currency, t.transactionValue) }}
            </td>
            <td class="text-center">
              {{ convert_date(t.date) }}
            </td>
            <td v-show="is_admin" class="text-center">
              <v-btn
                :data-test="`Vehicle:TransactionsTable:BtnEditTransaction${t.stockQuantity}`"
                class="px-2 ml-1 secondary"
                @click="open_dialog(t)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                :data-test="`Vehicle:TransactionsTable:BtnDeleteTransaction${t.stockQuantity}`"
                class="px-2 ml-1"
                @click="open_delete_dialog(t)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-model="dialog" v-if="dialog" scrollable>
      <DashboardFormsAddTransactionModal
        :transaction_prop="editTransaction"
        :company="company"
        :investors="investors"
        @close="reset_dialog"
      ></DashboardFormsAddTransactionModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="$t('this_transaction')"
        @close="delete_dialog = false"
        @delete="delete_transaction"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DashboardFormsAddTransactionModal from "@/views/pages/company/transactions/form/AddTransactionModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import moment from "moment";
import { formatCurrency } from "@/shared/helpers/currencyHelper";

export default {
  name: "DashboardTransactionsTable",
  components: {
    DashboardFormsAddTransactionModal,
    DeleteConfirmationModal,
  },
  async created() {
    this.company_id = this.company.CompanyId;
  },
  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    delete_dialog: false,
    delete_obj: null,
    dialog: false,
    editTransaction: null,
    company_id: "",
    formatToCurrency: formatCurrency,
  }),
  methods: {
    open_delete_dialog: function (transaction) {
      this.delete_obj = transaction;
      this.delete_dialog = true;
    },
    delete_transaction: function (transaction) {
      this.$parent.transactions_loading = true;
      this.apiService
        .deleteRequest(`transaction/delete/${transaction.id}`)
        .then((resp) => {
          var index = this.transactions.indexOf(transaction);
          this.$parent.transactions.splice(index, 1);
          this.$parent.transactions_loading = false;
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$parent.transactions_loading = false;
        });
    },
    open_dialog: function (transaction) {
      this.editTransaction = transaction;
      this.dialog = true;
    },
    reset_dialog(transaction) {
      this.$emit("loadingTransaction");
      this.dialog = false;
      if (transaction != null) {
        this.$parent.company_loading = true;
        if (!this.editTransaction) {
          this.$parent.transactions = [transaction].concat(
            this.$parent.transactions
          );
        } else {
          var index = this.$parent.transactions.indexOf(this.editTransaction);
          this.$parent.transactions[index] = transaction;
        }
      }
      this.editTransaction = null;
    },
    convert_date(date) {
      let m_date = moment(date);
      return m_date.format("DD/MM/YYYY");
    },
  },
  props: {
    investors: Array,
    company: Object,
    transactions: Array,
    is_admin: Boolean,
    can_edit: Boolean,
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  #btn-add-new-transaction {
    margin-right: 0px;
  }
}
</style>
