<template>
  <v-container id="kpi-table" fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-bullseye-arrow"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ Kpi.Name }}</b>
        </h1></template
      >
      <v-btn
        absolute
        fab
        top
        right
        color="secondary"
        @click="$emit('reset')"
        rounded
      >
        <v-icon x-large> mdi-arrow-left-bold </v-icon>
      </v-btn>
      <!-- <v-btn
        v-show="is_admin"
        absolute
        fab
        top
        right
        color="primary"
        @click="open_dialog()"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn> -->
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t("date") }}</th>
            <th class="text-center">{{ $t("current_value") }}</th>
            <th class="text-center">{{ $t("edit") }}</th>
            <th class="text-center">{{ $t("remove") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(history, i) in Kpi.KpiValueHistory" :key="i">
            <td class="text-center">
              {{ get_date_formated(history.Date) }}
            </td>
            <td class="text-center">
              {{ history.Value }}
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_edit_dialog(history)"
                color="secondary"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(history)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <!-- <v-dialog v-model="dialog" v-if="dialog">
      <DashboardFormsAddKpiModal
        :company="company"
        @close="reset_dialog"
      ></DashboardFormsAddKpiModal>
    </v-dialog> -->
    <v-dialog v-model="dialogEdit" v-if="dialogEdit">
      <EditKpiHistoryModal
        :History="editKpi"
        @close="reset_dialogEdit"
      ></EditKpiHistoryModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="$t('this_kpi')"
        @close="delete_dialog = false"
        @delete="delete_kpi"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import DashboardFormsAddKpiModal from "@/views/pages/company/kpis/form/AddKpiModal";
import EditKpiHistoryModal from "@/views/pages/company/kpis/form/EditKpiHistoryModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";

export default {
  name: "KpiHistoryTable",
  components: {
    // DashboardFormsAddKpiModal,
    // DashboardFormsEditKpiModal,
    EditKpiHistoryModal,
    DeleteConfirmationModal,
  },
  async created() {},
  data: () => ({
    rerender: true,
    apiService: new ApiService(),
    delete_dialog: false,
    delete_obj: null,
    dialog: false,
    company_id: "",
    editKpi: null,
    dialogEdit: false,
  }),
  props: {
    Kpi: Object,
  },
  methods: {
    get_date_formated: function (date) {
      date = new Date(date.replace("T", " "));
      var day = date.getUTCDate().toString().padStart(2, "0");
      var month = (date.getMonth() + 1).toString().padStart(2, "0");
      var year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    open_dialog: function () {
      this.dialog = true;
    },
    open_edit_dialog(kpi) {
      this.editKpi = kpi;
      this.dialogEdit = true;
    },
    reset_dialogEdit(kpis) {
      this.dialogEdit = false;
      if (kpis != null) {
        this.$emit("reload", kpis);
      }
    },
    reset_dialog(kpis) {
      this.dialog = false;
      if (kpis != null) {
        this.$parent.reload_company_kpis();
      }
    },
    open_delete_dialog: function (kpi) {
      this.delete_obj = kpi;
      this.delete_dialog = true;
    },
    delete_kpi: async function (history) {
      var index = this.Kpi.KpiValueHistory.indexOf(history);
      this.Kpi.KpiValueHistory.splice(index, 1);
      this.delete_dialog = false;
      await this.apiService
        .deleteRequest("kpi/history/delete", history)
        .then((resp) => {})
        .catch((error) => {});
    },
  },
};
</script>
