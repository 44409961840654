<template>
  <v-container
    id="add-stock-modal"
    style="justify-content: center !important; display: flex !important"
    fluid
    tag="section"
  >
    <v-card class="dxa_modal">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">{{ $t("stocks") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form">
          <v-select
            v-model="stock_selected"
            :items="stocks"
            item-value="Id"
            item-text="Name"
            :label="$t('stocks')"
          ></v-select>
          <v-text-field
            v-model="stock_value"
            :label="$tc('stock', 1)"
            type="number"
            class="pt-0"
          />

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-alert class="ma-2" v-if="alert_msg != null" type="error">
          {{ $t(alert_msg) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "AddStockModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    stocks: [],
    alert_msg: null,
    stock_selected: null,
    stock_value: 0,
    stock_type: null,
  }),
  props: {
    ExistingStocksIds: Array,
    stock_prop: Object,
    company: Object,
  },
  async created() {
    this.loading = true;
    this.apiService
      .getRequest("stock")
      .then((resp) => {
        this.stocks = JSON.parse(resp.message).filter(
          (x) => !this.ExistingStocksIds.includes(x.Id)
        );
        this.loading = false;
      })
      .catch((error) => {});
  },
  methods: {
    submit: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.alert_msg = null;

      let stockType = {
        Stocks: [
          {
            value: parseFloat(this.stock_value),
            stockTypeId: this.stock_selected,
          },
        ],
        companyId: this.company.CompanyId,
      };

      this.apiService
        .postRequest(`stock/add`, stockType)
        .then((result) => {
          var history = JSON.parse(result.message);
          history.StockTypes[0].StockType = this.stocks.filter(
            (x) => x.Id == this.stock_selected
          )[0];
          this.$emit("close", history);
          this.$toast.success(this.$t("stock_saved_success"));
          this.loading = false;
        })
        .catch((error) => {
          this.alert_msg = this.$t(error.body.message);
          this.loading = false;
        });
    },
  },
};
</script>
