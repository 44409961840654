<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'" class="modal-container">
    <div class="mb-6 modal-title-container">
      <h4 class="dxa_modal_title h4">{{ page_title() }}</h4>
    </div>
    <v-card-text>
      <v-form @submit.stop.prevent="checkForm()" ref="form">
        <v-select
          data-test="Vehicle:AddTransactionModal:InvestorEmail"
          v-if="!edit"
          v-model="user_selected"
          :rules="[required]"
          return-object
          @change="getInvestorCompleteObject()"
          :items="investors"
          item-text="InvestorEmail"
          :label="$tc('user', 1)"
        ></v-select>

        <v-select
          data-test="Vehicle:AddTransactionModal:Action"
          @change="checkInvestorStocks()"
          v-model="transaction.Action"
          :rules="[required]"
          item-text="text"
          item-value="value"
          :items="[
            { text: $t('buy_action'), value: TransactionActionsEnum.BUY },
            { text: $t('sell_action'), value: TransactionActionsEnum.SELL },
          ]"
          :label="$t('action')"
        ></v-select>

        <v-select
          data-test="Vehicle:AddTransactionModal:Vehicle"
          v-model="investor_vehicle_select"
          :items="investor_vehicles"
          item-text="Name"
          item-value="Id"
          :label="$t('vehicle_title_single')"
          @change="getStockTypeUserSelected()"
          v-if="!transaction_prop"
        ></v-select>

        <v-select
          data-test="Vehicle:AddTransactionModal:Currency"
          v-if="!transaction_prop"
          v-model="investor_currency_select"
          :items="investor_currencys"
          item-text="Name"
          item-value="Id"
          :label="$t('currency')"
          @change="getStockTypeUserSelected()"
        ></v-select>

        <v-select
          @change="handleStockType"
          data-test="Vehicle:AddTransactionModal:StockType"
          v-model="stock_type"
          :rules="[required]"
          :items="stock_type_options"
          item-text="Name"
          :label="$t('stock_type')"
        ></v-select>

        <div v-if="!transaction_prop && !disabledInputStockValue">
          <div>
            <span class="v-label theme--light">
              {{ $tc("stock", 1) }}
            </span>
            <v-text-field
              data-test="Vehicle:AddTransactionModal:StockValue"
              :prefix="moneyInputPrefix"
              v-model="investorStockValue"
              :disabled="disabledStockValue"
              :rules="[required]"
              type="number"
            >
            </v-text-field>
          </div>

          <div>
            <v-checkbox
              class="field"
              color="primary"
              outlined
              dense
              v-model="editStockValue"
            >
              <template v-slot:label>
                <span style="color: var(--dark)">{{
                  $t("edit_stock_value")
                }}</span>
              </template>
            </v-checkbox>
          </div>
        </div>

        <v-text-field
          data-test="Vehicle:AddTransactionModal:StockQuantity"
          type="number"
          v-model="transaction.StockQuantity"
          :rules="[required]"
          :min="0"
          :label="$t('current_position')"
          class="pt-0"
        />

        <v-autocomplete
          v-model="transaction.InvestmentRoundId"
          :items="roundOptions"
          item-text="id"
          item-value="id"
          :label="$t('single_round')"
          dense
          id="userInput"
          :loading="roundOptionsLoading"
        >
        </v-autocomplete>
        <!-- <v-checkbox
            v-if="transaction.Action === 0 && !edit"
            v-model="transaction.DeleteAllocations"
            :label="$t('clean_allocations')"
          ></v-checkbox> -->

        <v-menu
          ref="menuDate"
          v-model="menuDate"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('date')"
              prepend-icon="mdi-calendar"
              class="pt-0"
              :rules="[required]"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            class="mx-auto"
            v-model="date"
            @change="
              $refs.menuDate.save(date);
              menuDate = false;
            "
          >
          </v-date-picker>
        </v-menu>

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            data-test="Vehicle:AddTransactionModal:BtnSaveTransaction"
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close', null)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import {
  getCurrencyPrefix,
  formatCurrency,
} from "@/shared/helpers/currencyHelper";
import {
  formatToDecimalNumber,
  convertIntNumberInDecimal,
} from "@/shared/helpers/inputHelpers.js";
import GeneralServices from "@/services/GeneralServices";
import moment from "moment";
import { TransactionActionsEnum } from "@/shared/enums/TransactionEnum";

export default {
  name: "DashboardFormsAddTransactionModal",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    existing_user: true,
    other_investor: "",
    other_investor_object: null,
    edit: false,
    selectedAction: false,
    stock_type: null,
    investor_vehicles: null,
    investor_vehicle_select: null,
    investor_currency_select: null,
    investor_currencys: null,
    investorStockValue: null,
    getCurrencyPrefixData: getCurrencyPrefix,
    transaction: {
      InvestmentId: null,
      InvestorName: null,
      InvestorEmail: null,
      CompanyId: null,
      StockTypeId: null,
      StockQuantity: null,
      Action: 0,
      DeleteAllocations: false,
      InvestmentRoundId: null,
      DepositedDate: null,
    },
    stocks: [],
    user_selected: null,
    stock_type_options: null,
    filtered_stocktype_options_by_selectedUser: null,
    investor: {
      UserId: null,
      CompanyId: null,
      Role: null,
      StockType: null,
      StockQuantity: null,
    },
    vehicleStockTypes: [],
    disabledInputStockValue: true,
    editStockValue: false,
    disabledStockValue: true,
    moneyInputPrefix: "R$",
    roundOptions: [],
    roundOptionsLoading: false,
    menuDate: false,
    date: null,
    TransactionActionsEnum,
  }),
  props: {
    transaction_prop: Object,
    investors: Array,
    company: Object,
  },
  computed: {
    required() {
      return (value) => value != null || this.$t("required");
    },
  },
  async created() {
    this.loading = true;
    this.stock_type_options = [];
    await this.reload_stocks();

    if (this.transaction_prop != null) {
      this.edit = true;
      this.transaction = {
        Id: this.transaction_prop.id,
        InvestmentId: this.transaction_prop.investmentId,
        InvestorName: this.transaction_prop.investment.investorName,
        InvestorEmail: this.transaction_prop.investment.investorEmail,
        CompanyId: this.transaction_prop.investment.companyId,
        StockTypeId: this.transaction_prop.stockTypeId,
        StockQuantity: this.transaction_prop.stockQuantity,
        Action: this.transaction_prop.action,
        InvestmentRoundId: this.transaction_prop.investmentRoundId,
        DepositedDate: this.transaction_prop.date,
      };
      this.date = moment(this.transaction.DepositedDate).format("YYYY-MM-DD");
      this.investor_vehicle_select =
        this.transaction_prop.investment.investmentVehicleId;
      this.other_investor_object = this.investors.filter(
        (x) => x.Id === this.transaction_prop.investmentId
      )[0];

      this.getSelectedStockType();
      await this.getRoundOptions();
    } else {
      this.date = moment().format("YYYY-MM-DD");
      this.edit = false;
    }
    this.loading = false;
  },
  watch: {
    investorStockValue() {
      if (this.investorStockValue) {
        this.moneyInputPrefix = getCurrencyPrefix(
          this.investor_currency_select
        );
      }
    },
    editStockValue() {
      if (this.editStockValue) {
        this.disabledStockValue = false;
        return;
      }

      this.disabledStockValue = true;
    },
  },
  methods: {
    getSelectedStockType() {
      this.sto = [];
      this.stock_type_options.push(
        this.stocks.filter((x) => x.Id === this.transaction.StockTypeId)[0].Name
      );
      this.stock_type = this.stock_type_options[0];
    },
    async getRoundOptions() {
      this.roundOptionsLoading = true;

      let payload = {
        investmentVehicleId: this.investor_vehicle_select,
        currency: this.investor_currency_select,
        stockTypeId: (this.transaction.StockTypeId = this.stocks.filter(
          (x) => x.Name === this.stock_type
        )[0].Id),
        companyId: this.company.CompanyId,
      };

      if (this.transaction_prop) {
        payload.currency = this.transaction_prop.investment.currency;
      }

      await this.apiService
        .postRequest(`investmentround/filter`, payload)
        .then((resp) => {
          this.roundOptions = resp.content.items;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.roundOptionsLoading = false;
    },
    async getVehicleStocksTypes() {
      if (this.investor_vehicle_select && this.investor_currency_select) {
        await this.apiService
          .getRequest(
            `investmentvehicle/vehiclestockvalue/${this.investor_vehicle_select}?currency=${this.investor_currency_select} `
          )
          .then((resp) => {
            this.vehicleStockTypes = resp.content.vehicleStockTypes;
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_occurred"));
          });
      }
    },
    async handleStockType(selectedStock) {
      await this.getVehicleStocksTypes();
      await this.getRoundOptions();

      if (this.vehicleStockTypes) {
        const stockType = this.vehicleStockTypes.find(
          (stock) => stock.stockTypeName === selectedStock
        );

        if (stockType) {
          this.investorStockValue = convertIntNumberInDecimal(stockType.value);
          this.disabledInputStockValue = false;
        }
      }
    },
    async reload_stocks() {
      if (this.transaction_prop) {
        await this.apiService
          .getRequest(`stock/id/${this.transaction_prop.stockTypeId}`)
          .then((resp) => {
            this.stocks = JSON.parse(resp.message);
            JSON.parse(resp.message).forEach((s) => {
              this.stock_type_options.push(s.Name);
            });
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_occurred"));
          });
      } else {
        await this.apiService
          .getRequest(`stock/${this.company.CompanyId}`)
          .then((resp) => {
            this.stocks = JSON.parse(resp.message);
            JSON.parse(resp.message).forEach((s) => {
              this.stock_type_options.push(s.Name);
            });
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_occurred"));
          });
      }
    },
    max_stocks() {
      return (
        this.other_investor_object.StocksQuantity.filter(
          (x) =>
            x.StockTypeId ===
            this.stocks.filter((s) => s.Name === this.stock_type)[0].Id
        )[0].CurrentPosition +
        (this.edit
          ? this.transaction_prop.action == 0
            ? this.transaction_prop.stockQuantity * -1
            : this.transaction_prop.stockQuantity
          : 0)
      );
    },
    page_title() {
      if (this.edit && !this.loading) {
        return this.$t("edit") + " " + this.$tc("transaction", 1);
      } else if (!this.edit) {
        return this.$t("new_transaction");
      } else {
        return "";
      }
    },
    getInvestorCompleteObject() {
      this.other_investor_object = this.investors.filter(
        (x) => x.InvestorEmail === this.user_selected.InvestorEmail
      )[0];

      this.getVehicleUserSelected();
    },

    getVehicleUserSelected() {
      this.investor_vehicles = [];

      let vehiclesUser = this.investors.filter(
        (x) => x.UserId === this.user_selected.UserId
      );
      vehiclesUser.forEach((e) => {
        this.investor_vehicles.push(e.InvestmentVehicle);
      });
    },

    getStockTypeUserSelected() {
      this.investorStockValue = 0;

      if (!this.investor_currencys) {
        this.investor_currencys = [];
      }

      let stockTypesUser = this.investors.filter(
        (x) => x.UserId === this.user_selected.UserId
      );
      let stockTypeUserId = [];
      stockTypesUser.forEach((e) => {
        if (e.InvestmentVehicleId == this.investor_vehicle_select) {
          if (!this.investor_currency_select) {
            this.investor_currencys.push({
              Id: e.Currency,
              Name: this.getCurrencyPrefixData(e.Currency),
            });
          }
          if (e.Currency == this.investor_currency_select) {
            e.Transactions.forEach((t) => {
              stockTypeUserId.push(t.StockTypeId);
            });
          }
        }
      });
      stockTypeUserId = [...new Set(stockTypeUserId)];
      this.investor_currency = [...new Set(this.investor_currency)];
      this.stock_type_options = [];

      this.stocks.forEach((s) => {
        if (stockTypeUserId.includes(s.Id)) {
          this.stock_type_options.push(s.Name);
        }
      });
    },

    getInvestmentIdUserSelected() {
      let investmentsId = this.investors.filter(
        (x) =>
          x.UserId === this.user_selected.UserId &&
          x.Currency === this.investor_currency_select &&
          x.InvestmentVehicleId === this.investor_vehicle_select
      );

      let stockTypeId = this.stocks.filter((x) => x.Name === this.stock_type)[0]
        .Id;

      let investmentId = -1;

      investmentsId.forEach((e) => {
        e.Transactions.forEach((t) => {
          if (t.StockTypeId == stockTypeId) {
            investmentId = e.Id;
          }
        });
      });

      return investmentId;
    },

    checkInvestorStocks() {
      this.selectedAction = true;
      this.filtered_stocktype_options_by_selectedUser = [];
      if (
        this.other_investor_object &&
        this.transaction.Action == this.TransactionActionsEnum.SELL
      ) {
        this.other_investor_object.StocksQuantity.forEach((sq) => {
          let option = this.stocks.filter((x) => x.Id === sq.StockTypeId)[0];
          this.filtered_stocktype_options_by_selectedUser.push(option.Name);
        });
      }
    },
    checkForm() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      let result = this.$refs.form.validate();

      if (result) {
        this.submit();
      } else {
        this.loading = false;
      }
    },
    async submit() {
      this.transaction.DepositedDate = this.date.toString() + "T00:00:00";
      if (this.edit) {
        // TODO : Verificar o pq da rota estar editando um investment e não uma transaction
        await this.apiService
          .putRequest("transaction/update", this.transaction)
          .then((result) => {
            this.loading = false;
            this.transaction_prop.stockQuantity =
              this.transaction.StockQuantity;
            this.transaction_prop.action = this.transaction.Action;
            this.transaction_prop.stockTypeId = this.transaction.StockTypeId;
            this.transaction_prop.date = this.transaction.DepositedDate;
            this.$emit("close", null);
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(this.$t("an_error_occurred"));
          });
      } else {
        if (this.transaction.Action === this.TransactionActionsEnum.BUY) {
          this.transaction.Action = this.TransactionActionsEnum.BUY;
        } else {
          this.transaction.Action = this.TransactionActionsEnum.SELL;
        }

        let investmentId = this.getInvestmentIdUserSelected();
        this.transaction.InvestmentId = investmentId;
        this.transaction.InvestorName = this.other_investor_object.InvestorName;
        this.transaction.InvestorEmail =
          this.other_investor_object.InvestorEmail;
        this.transaction.CompanyId = this.other_investor_object.CompanyId;
        this.transaction.StockTypeId = this.stocks.filter(
          (x) => x.Name === this.stock_type
        )[0].Id;
        this.transaction.StockValue = formatToDecimalNumber(
          this.investorStockValue
        );

        await this.apiService
          .postRequest("transaction/add", this.transaction)
          .then((result) => {
            this.loading = false;
            this.$emit("close", null);
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(this.$t("an_error_occurred"));
          });
      }
    },
  },
};
</script>

<style scoped>
.modal-title-container {
  padding-left: 20px;
  padding-top: 20px;
}

.money {
  width: 100%;
  border-bottom: 1px solid #d2d2d2 !important;
  color: #495057;
  margin-bottom: 20px;
}

.money:focus {
  outline: none;
}

.money:disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
  border-bottom-style: dashed !important;
}
</style>
