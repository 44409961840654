<template>
  <v-container id="files" fluid tag="section">
    <base-material-card
      color="#7733FF"
      icon="mdi-clipboard-text"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h4">
          <b>{{ $t("files") }}</b>
        </h1></template
      >
      <v-btn
        v-show="is_admin"
        absolute
        fab
        top
        right
        @click="open_dialog()"
        color="primary"
        rounded
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th class="text-center">
              {{ $t("download") }}
            </th>
            <th class="text-center" v-show="is_admin">
              {{ $t("edit") }}
            </th>
            <th v-show="is_admin" class="text-center">
              {{ $t("remove") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(file, i) in files.filter(
              (x) => gs.getLanguage(x.Language) === $i18n.locale
            )"
            :key="i"
          >
            <td>{{ file.FileName }}</td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1 btnDownloadFile"
                icon
                color="primary"
                @click="download_file(file)"
                min-width="0"
                small
                :loading="fileDownloading == file"
              >
                <v-icon small>mdi-download</v-icon>
              </v-btn>
            </td>
            <td class="text-center" v-show="is_admin">
              <v-btn
                class="px-2 ml-1 secondary"
                @click="edit_dialog(file)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-show="is_admin" class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(file)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-model="dialog">
      <div v-if="dialog">
        <DashboardFormsAddFileModal
          :company_id="company_id"
          :index_options="options_param"
          :file="file"
          @close="dialog = false"
          @reset="reset_dialog"
        ></DashboardFormsAddFileModal>
      </div>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.FileName"
        @close="delete_dialog = false"
        @delete="delete_file"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import CompanyServices from "@/services/CompanyServices";
import DashboardFormsAddFileModal from "@/views/pages/company/files/form/AddFileModal";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";

export default {
  name: "DashboardFilesTable",
  components: {
    DashboardFormsAddFileModal,
    DeleteConfirmationModal,
  },
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    cs: new CompanyServices(),
    dialog: false,
    edit: false,
    options_param: null,
    file: null,
    files: [],
    fileDownloading: null,
    delete_dialog: false,
    delete_obj: null,
  }),
  async created() {
    this.refresh_files();
  },
  computed: {
    index_options() {
      var i;
      var return_list = [];
      for (
        i = 1;
        i <=
        this.files.filter(
          (x) => this.gs.getLanguage(x.Language) === this.$i18n.locale
        ).length;
        i++
      ) {
        return_list.push(i);
      }
      if (!this.edit) {
        return_list.push(
          this.files.filter(
            (x) => this.gs.getLanguage(x.Language) === this.$i18n.locale
          ).length + 1
        );
      }
      return return_list;
    },
  },
  methods: {
    async download_file(file) {
      this.fileDownloading = file;
      await this.cs.download_company_file(file.Id);
      this.fileDownloading = null;
    },
    refresh_files() {
      if (this.files_prop != null) {
        this.files = this.files_prop.filter((x) => x.Type == 0);
      }
    },
    open_dialog: function () {
      this.file = null;
      this.edit = false;
      this.options_param = this.index_options;
      this.dialog = true;
    },
    edit_dialog: function (file) {
      this.file = file;
      this.edit = true;
      this.options_param = this.index_options;
      this.dialog = true;
    },
    reset_dialog(files) {
      this.dialog = false;
      this.$parent.company.Files.splice(0, this.$parent.company.Files.length);
      var i;
      for (i = 0; i < files.length; i++) {
        this.$parent.company.Files.push(files[i]);
      }
      this.refresh_files();
    },
    open_delete_dialog: function (file) {
      this.delete_obj = file;
      this.delete_dialog = true;
    },
    delete_file: async function (file) {
      await this.apiService
        .deleteRequest(`companyfile/delete/details/${file.Id}`)
        .then((resp) => {
          var objs = JSON.parse(resp.message);
          this.reset_dialog(objs);
          this.refresh_files();
          this.delete_dialog = false;
        })
        .catch((error) => {});
    },
  },
  props: {
    company_id: Number,
    files_prop: Array,
    is_admin: Boolean,
  },
};
</script>
