<template>
  <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
    <v-card-title class="mb-2">
      <h4 class="dxa_modal_title h4">{{ page_title() }}</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.stop.prevent="submit()" ref="form">
        <div v-if="!edit">
          <v-radio-group v-model="existing_user" row>
            <v-radio :label="$t('existing_user')" :value="true"></v-radio>
            <v-radio :label="$t('other_user')" :value="false"></v-radio>
          </v-radio-group>
        </div>

        <v-autocomplete
          v-if="existing_user && !edit"
          v-model="user_selected"
          :items="users"
          item-text="email"
          item-value="id"
          :rules="[required]"
          :label="$tc('user', 1)"
          dense
          @keyup="getUsers"
          id="userInput"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ userTextFollback ? $t("write_user") : $t("not_found_user") }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>

        <div v-else-if="!existing_user">
          <v-text-field
            :rules="[required]"
            v-model="investor.InvestorName"
            :label="$tc('investor', 1)"
            class="pt-0"
          />
          <v-text-field
            :rules="[required]"
            v-if="!edit"
            v-model="investor.InvestorEmail"
            :label="$t('email')"
            class="pt-0"
          />
        </div>

        <v-menu
          ref="menuDate"
          v-model="menuDate"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('date')"
              prepend-icon="mdi-calendar"
              class="pt-0"
              :rules="[required]"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            class="mx-auto"
            v-model="date"
            @change="
              $refs.menuDate.save(date);
              menuDate = false;
            "
          >
          </v-date-picker>
        </v-menu>

        <v-combobox
          :rules="[required]"
          v-model="vehicleSelected"
          :items="vehicles"
          item-text="InvestmentVehicle.Name"
          item-value="InvestmentVehicle.Id"
          :label="$t('vehicle_title_single')"
        ></v-combobox>
        <v-select
          v-if="!edit"
          :rules="[required]"
          v-model="investor.Currency"
          :items="currencyOptions"
          item-text="text"
          item-value="value"
          :label="$t('currency')"
          dense
          color="primary"
          @change="getVehicleStocks"
        ></v-select>
        <v-text-field
          :rules="[required]"
          v-model="investor.Role"
          :label="$t('role')"
          class="pt-0"
        />

        <div v-if="!edit">
          <v-select
            v-model="stock_type"
            :rules="[required]"
            :items="stocks"
            :loading="stocksLoading"
            return-object
            @change="handleStockType"
            :label="$t('stock_type')"
            item-text="stockTypeName"
            item-value="stockTypeId"
          />
          <v-autocomplete
            v-if="!edit"
            v-model="investor.InvestmentRoundId"
            :items="roundOptions"
            item-text="id"
            item-value="id"
            :label="$t('single_round')"
            dense
            id="userInput"
            :loading="roundOptionsLoading"
          >
          </v-autocomplete>

          <div v-if="!edit && !disabledInputStockValue">
            <div>
              <span class="v-label theme--light">
                {{ $tc("stock", 1) }}
              </span>
              <v-text-field
                :prefix="moneyInputPrefix"
                v-model="investor.StockValue"
                :disabled="disabledStockValue"
                :rules="[required]"
                type="number"
              >
              </v-text-field>
            </div>

            <div>
              <v-checkbox
                class="field"
                color="primary"
                outlined
                dense
                v-model="editStockValue"
              >
                <template v-slot:label>
                  <span style="color: var(--dark)">{{
                    $t("edit_stock_value")
                  }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>

          <v-text-field
            type="number"
            :rules="[required]"
            v-model="investor.StockQuantity"
            :label="$t('current_position')"
            class="pt-0"
          />

          <!-- <v-checkbox
              v-if="existing_user"
              v-model="investor.DeleteAllocations"
              :label="$t('clean_allocations')"
            ></v-checkbox> -->
        </div>

        <v-card-actions class="pl-0 dxa_modal_actions">
          <v-btn
            class="dxa_modal_btnSuccess"
            color="primary"
            min-width="100"
            :loading="loading"
            type="submit"
            >{{ $t("save") }}</v-btn
          >
          <v-btn
            class="dxa_modal_btnError"
            color="red"
            min-width="100"
            @click="$emit('close', null)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-alert class="ma-2" v-if="error" type="error">{{ $t(error) }}</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";
import {
  formatCurrency,
  getCurrencyPrefix,
} from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import {
  formatToDecimalNumber,
  convertIntNumberInDecimal,
} from "@/shared/helpers/inputHelpers.js";
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "DashboardFormsAddInvestorModal",
  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    existing_user: true,
    row: null,
    editStockValue: false,
    disabledStockValue: true,
    edit: false,
    users: [],
    error: null,
    user_selected: null,
    stock_type: null,
    menuDate: false,
    date: null,
    stocksLoading: false,
    vehicles: null,
    vehicleSelected: null,
    investor: {
      InvestorEmail: null,
      InvestorName: null,
      UserId: null,
      CompanyId: null,
      Role: null,
      DeleteAllocations: false,
      StockQuantity: null,
      Currency: null,
      StockValue: null,
      InvestmentRoundId: null,
    },
    stocks: [],
    userTextFollback: true,
    CurrencyEnum: CurrencyEnum,
    getCurrencyPrefix,
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
    moneyInputPrefix: "R$",
    disabledInputStockValue: true,
    roundOptions: [],
    roundOptionsLoading: false,
  }),
  props: {
    investor_prop: Object,
    company: Object,
  },
  watch: {
    "investor.Currency"() {
      if (this.investor.Currency) {
        this.moneyInputPrefix = getCurrencyPrefix(this.investor.Currency);
      }
    },
    vehicleSelected(e) {
      this.investor.StockValue = 0;
      this.stock_type = null;

      if (this.vehicleSelected && this.investor.Currency) {
        this.getVehicleStocks();
      }
    },
    editStockValue() {
      if (this.editStockValue) {
        this.disabledStockValue = false;
        return;
      }

      this.disabledStockValue = true;
    },
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  async created() {
    if (this.investor_prop != null) {
      this.edit = true;

      if (this.investor_prop.UserId == null) {
        this.existing_user = false;
        this.investor.InvestorName = this.investor_prop.InvestorName;
      } else {
        this.investor.UserId = this.investor_prop.UserId;
        this.investor.User = this.investor_prop.User;
      }

      this.investor.Id = this.investor_prop.Id;
      this.investor.InvestorName = this.investor_prop.InvestorName;
      this.investor.InvestorEmail = this.investor_prop.InvestorEmail;
      this.investor.CompanyId = this.investor_prop.CompanyId;
      this.vehicles = this.company.CompanyInvestmentVehicles;
      this.vehicleSelected = this.investor_prop.InvestmentVehicle
        ? this.investor_prop.InvestmentVehicle.Name
        : null;
      this.investor.InvestmentVehicleId = this.investor_prop.InvestmentVehicle
        ? this.investor_prop.InvestmentVehicle.Id
        : 0;
      this.date = moment(this.investor_prop.Date).format("YYYY-MM-DD");
      this.investor.RoundId = this.investor_prop.RoundId;
      if (this.date == "0001-01-01") {
        this.date = moment().format("YYYY-MM-DD");
      }
      this.investor.Role = this.investor_prop.Role;
      this.investor.Currency = this.investor_prop.Currency;
    } else {
      this.edit = false;
      this.loading = false;
      this.vehicles = this.company.CompanyInvestmentVehicles;
      this.investor = {
        UserId: null,
        CompanyId: this.company.CompanyId,
        Role: null,
        StockType: null,
        StockQuantity: null,
        DeleteAllocations: false,
        InvestmentVehicleId: null,
        Currency: null,
        StockValue: null,
      };
    }
  },
  methods: {
    async getRoundOptions() {
      this.roundOptionsLoading = true;

      let payload = {
        investmentVehicleId: this.vehicleSelected.InvestmentVehicleId,
        currency: this.investor.Currency,
        stockTypeId: this.stock_type.stockTypeId,
        companyId: this.company.CompanyId,
      };

      await this.apiService
        .postRequest(`investmentround/filter`, payload)
        .then((resp) => {
          this.roundOptions = resp.content.items;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
      this.roundOptionsLoading = false;
    },
    async handleStockType() {
      this.investor.StockTypeId = this.stock_type.stockTypeId;
      await this.getRoundOptions();

      if (this.stock_type) {
        this.investor.StockValue = convertIntNumberInDecimal(
          this.stock_type.value
        );

        this.disabledInputStockValue = false;
      }
    },
    async getVehicleStocks() {
      const { InvestmentVehicleId } = this.vehicleSelected;

      if (InvestmentVehicleId) {
        this.stocksLoading = true;

        await this.apiService
          .getRequest(
            `investmentvehicle/vehiclestockvalue/${InvestmentVehicleId}?currency=${this.investor.Currency}`
          )
          .then((resp) => {
            this.stocks = resp.content.vehicleStockTypes;
          })
          .catch((error) => {
            this.$toast.error(this.$t("an_error_ocurred"));
          });

        this.stocksLoading = false;
      }
    },
    show_share_value: function () {
      if (this.stock_type && this.vehicleSelected) {
        return (
          this.$t("share_value_from") +
          " " +
          this.company.Name +
          " " +
          formatCurrency(this.stock_type.currency, this.stock_type.value)
        );
      }
    },
    page_title: function () {
      if (this.edit) {
        return this.$t("manage_investor");
      } else {
        return this.$t("new_investor");
      }
    },
    select_user: function (user_id) {
      this.users.forEach((element) => {
        if (element.Id === user_id) {
          this.user_selected = element;
        }
      });
    },
    submit: function () {
      var result = this.$refs.form.validate();
      if (this.loading || !result) {
        return;
      }
      this.error = null;
      this.loading = true;
      if (this.date != null) {
        this.investor.Date = this.date.toString() + "T00:00:00";
      } else {
        this.investor.Date = moment().format("YYYY-MM-DD") + "T00:00:00";
      }

      this.investor.StockValue = formatToDecimalNumber(
        this.investor.StockValue
      );

      if (this.edit) {
        if (!this.investor.StockQuantity) {
          this.investor.StockQuantity = 0;
        }

        const vehicleEdited = typeof this.vehicleSelected;
        if (vehicleEdited !== "string") {
          this.investor.InvestmentVehicleId =
            this.vehicleSelected.InvestmentVehicle.Id;
        }

        // até ajustar a edição vai sempre null o valor no update
        // o back faz o tratamento para não zerar no banco
        this.investor.StockValue = null;

        this.apiService
          .putRequest("investor/edit", this.investor)
          .then((result) => {
            this.loading = false;
            this.$emit("close", this.investor);
            // this.$emit("update");
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        if (this.existing_user) {
          this.investor.UserId = this.user_selected;
        }

        if (this.vehicleSelected) {
          this.investor.InvestmentVehicleId =
            this.vehicleSelected.InvestmentVehicle.Id;
        } else {
          this.investor.InvestmentVehicleId = 0;
        }
        this.apiService
          .postRequest("investor/new", this.investor)
          .then((result) => {
            this.loading = false;
            this.investor.Id = result.content.id;
            this.$emit("close", this.investor);
          })
          .catch((error) => {
            this.error = error.body.message;
            this.loading = false;
          });
      }
    },
    async getUsers() {
      const userInput = document.getElementById("userInput");
      const userValue = userInput.value;
      let payload = JSON.stringify(userValue);

      if (userValue.length >= 3) {
        this.loading = true;
        await this.apiService
          .postRequest("investor/filter-stakeholders", payload)
          .then((result) => {
            this.users = result.content;
            this.loading = false;
          })
          .catch((err) => {
            this.$toast.error(this.$t("error_occoured"));
            this.loading = false;
          });
      }

      if (userValue.length < 3) {
        this.userTextFollback = true;
      } else {
        this.userTextFollback = false;
      }
    },
  },
};
</script>

<style scoped>
.money {
  width: 100%;
  border-bottom: 1px solid #d2d2d2 !important;
  color: #495057;
  margin-bottom: 20px;
}

.money:focus {
  outline: none;
}

.money:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  border-bottom-style: dashed !important;
}
</style>
