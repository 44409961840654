import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"id":"files","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3 mb-5",attrs:{"color":"#7733FF","icon":"mdi-clipboard-text","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('h1',{staticClass:"h4"},[_c('b',[_vm._v(_vm._s(_vm.$t("files")))])])]},proxy:true}])},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.is_admin),expression:"is_admin"}],attrs:{"absolute":"","fab":"","top":"","right":"","color":"primary","rounded":""},on:{"click":function($event){return _vm.open_dialog()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-plus")])],1),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("download"))+" ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_admin),expression:"is_admin"}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_admin),expression:"is_admin"}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])])]),_c('tbody',_vm._l((_vm.files.filter(
            function (x) { return _vm.gs.getLanguage(x.Language) === _vm.$i18n.locale; }
          )),function(file,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(file.FileName))]),_c('td',{staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 btnDownloadFile",attrs:{"icon":"","color":"primary","min-width":"0","small":"","loading":_vm.fileDownloading == file},on:{"click":function($event){return _vm.download_file(file)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-download")])],1)],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_admin),expression:"is_admin"}],staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.edit_dialog(file)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_admin),expression:"is_admin"}],staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1",attrs:{"color":"red","min-width":"0","small":""},on:{"click":function($event){return _vm.open_delete_dialog(file)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)],1)])}),0)])],1),_c(VDialog,{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('div',[_c('DashboardFormsAddFileModal',{attrs:{"company_id":_vm.company_id,"index_options":_vm.options_param,"file":_vm.file},on:{"close":function($event){_vm.dialog = false},"reset":_vm.reset_dialog}})],1):_vm._e()]),(_vm.delete_dialog)?_c(VDialog,{model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('DeleteConfirmationModal',{attrs:{"obj":_vm.delete_obj,"name":_vm.delete_obj.FileName},on:{"close":function($event){_vm.delete_dialog = false},"delete":_vm.delete_file}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }