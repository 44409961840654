<template>
  <v-app-bar
    absolute
    color="white"
    elevate-on-scroll
    scroll-target="#scrolling-techniques-7"
  >
    <v-toolbar-title class="mx-auto mb-10 pt-10"
      ><h1 class="appBar-title">{{ company_name }}</h1></v-toolbar-title
    >
    <template v-slot:extension>
      <v-tabs
        show-arrows
        grow
        centered
        background-color="transparent"
        v-model="tab"
      >
        <v-tab
          v-for="(item, index) in TabOptions"
          :key="index"
          @click="index_changed(item.index)"
          :data-test="`VehicleTransactions:AppBar:Opt${item.index + 1}`"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </template>
    <!-- <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark rounded color="primary" v-bind="attrs" v-on="on" small>
          <v-icon small>mdi-menu</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in get_items_translations"
          :key="index"
          @click="index_changed(item.index)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->
  </v-app-bar>
</template>

<script>
export default {
  name: "CompanyAppBar",
  data: () => ({
    collapseOnScroll: true,
    tab: 0,
  }),
  mounted() {
    for (var i = 0; i < this.TabOptions.length; i++) {
      if (this.$parent.selected_index == this.TabOptions[i].index) {
        this.tab = i;
        break;
      }
    }
  },
  methods: {
    index_changed: function (index) {
      this.$router.push({ query: { tab: index + 1 } });
      this.$parent.selected_index = index;
    },
  },
  props: {
    TabOptions: Array,
    admin_type: Number,
    company_name: String,
    UserType: Number,
  },
};
</script>

<style scoped>
.appBar-title {
  font-size: 40px;
  font-family: "Source Sans Pro";
  font-weight: bold;
  line-height: 58px;
}
</style>
