<template>
  <v-container id="add-investor-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">{{ page_title() }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="checkForm()" ref="form">
          <v-row class="mb-0">
            <v-col cols="8">
              <v-text-field
                v-model="event.Name"
                :rules="[required]"
                :label="$t('name')"
                class="pt-0"
              />
            </v-col>
            <v-col cols="4">
              <span>{{ $t("is_all_day") }}</span>
              <v-radio-group center v-model="all_day" row>
                <v-radio :label="$t('yes')" :value="true"></v-radio>
                <v-radio :label="$t('no')" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('date')"
                prepend-icon="mdi-calendar"
                class="pt-0 mt-0 mb-1"
                :rules="[required]"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              class="mx-auto"
              v-model="date"
              @change="
                $refs.menuDate.save(date);
                menuDate = false;
              "
            >
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="menuTime"
            v-if="!all_day"
            v-model="menuTime"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                :label="$t('time')"
                :rules="[required]"
                class="mb-1"
                prepend-icon="mdi-clock"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker v-if="menuTime" v-model="time" full-width>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuTime = false"
                >Cancel</v-btn
              >
              <v-btn text color="primary" @click="$refs.menuTime.save(time)"
                >OK</v-btn
              >
            </v-time-picker>
          </v-menu>
          <v-text-field
            v-model="event.Link"
            :label="$t('event_link')"
            class="pt-0"
          />
          <v-textarea
            outlined
            :label="$t('details')"
            v-model="event.Details"
          ></v-textarea>

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              >{{ $t("save") }}</v-btn
            >
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
              >{{ $t("cancel") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";
export default {
  name: "DashboardFormsAddEventModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    edit: false,
    menuDate: false,
    menuTime: false,
    all_day: true,
    time: null,
    date: null,
    event: {
      Name: null,
      CompanyId: null,
      Date: null,
      Details: null,
      Link: null,
    },
  }),
  props: {
    event_prop: Object,
    company: Object,
  },
  async created() {
    this.loading = true;
    this.event.CompanyId = this.company.CompanyId;
    if (this.event_prop != null) {
      this.edit = true;
      this.event.Id = this.event_prop.Id;
      this.event.Name = this.event_prop.name;
      this.event.Link = this.event_prop.link;
      this.event.Details = this.event_prop.details;
      this.event.Timestamp = this.event_prop.Timestamp;
      this.date = this.get_date_string(this.event_prop.start);
      this.time = this.get_time_string(this.event_prop.date);
      if (this.time != "00:00") {
        this.all_day = false;
      }
      this.loading = false;
    } else {
      this.edit = false;
      this.loading = false;
    }
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  methods: {
    get_date_string(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    get_time_string(date) {
      var moment_date = moment(date).zone("+0000");
      return moment_date.format("HH:mm");
    },
    page_title: function () {
      if (this.edit) {
        return this.event.Name;
      } else {
        return this.$t("new_event");
      }
    },
    checkForm: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        this.submit();
      } else {
        this.loading = false;
      }
    },
    submit: function () {
      if (!this.all_day) {
        this.event.Date =
          this.date.toString() + "T" + this.time.toString() + ":00";
      } else {
        this.event.Date = this.date.toString() + "T00:00:00";
      }
      if (this.edit) {
        this.apiService
          .putRequest("companyevent/edit", this.event)
          .then((result) => {
            this.loading = false;
            this.$emit("close", this.event, this.event.Id);
          })
          .catch((error) => {
            this.loading = false;
          });
      } else {
        this.apiService
          .postRequest("companyevent/new", this.event)
          .then((result) => {
            this.loading = false;
            this.event = {
              Name: null,
              CompanyId: null,
              Date: null,
              Link: null,
              Details: null,
            };
            this.$emit("close", JSON.parse(result.message));
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
