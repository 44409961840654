<template>
  <div>
    <v-row class="company-details-header">
      <v-col
        cols="12"
        :md="
          UserType == userTypeEnum.Admin ||
          UserType == userTypeEnum.FinancialAnalyst ||
          UserType == userTypeEnum.AlphaAnalyst
            ? 4
            : 6
        "
      >
        <v-container class="white" tag="section" rounded>
          <div class="ml-4">
            <div
              v-if="
                UserType == userTypeEnum.Admin ||
                UserType == userTypeEnum.FinancialAnalyst ||
                UserType == userTypeEnum.AlphaAnalyst
              "
            >
              <v-row>
                <h4 class="h4 div-info-title" style="color: var(--primary)">
                  {{ get_length(investors) }}
                </h4>
              </v-row>
              <v-row>
                <h5 class="h5 div-info-title">{{ $t("stakeholders") }}</h5>
              </v-row>
            </div>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-container
      v-if="!company.Pbi && charts.length > 0"
      class="white my-3"
      tag="section"
      fluid
      rounded
    >
      <v-row>
        <v-select
          v-model="selected_chart"
          :items="charts"
          item-text="name"
          item-value="index"
          dense
          @change="getDefaultGraph"
          outlined
          return-object
          class="chart-select mx-auto"
        ></v-select>
      </v-row>
      <div>
        <line-chart
          v-if="!loading && DefaultGraph == 1"
          :chartData="JSON.parse(JSON.stringify(selected_chart.data))"
          :options="selected_chart.options"
        />
        <bar-chart
          v-else-if="!loading && DefaultGraph == 0"
          :chartData="JSON.parse(JSON.stringify(selected_chart.data))"
          :options="selected_chart.options"
        />
      </div>
    </v-container>
    <v-container v-else-if="company.Pbi" class="white my-3" tag="section" fluid rounded>
      <div
        :class="gs.isMobile() ? 'div-powerbi-mobile' : 'div-powerbi'"
        id="ReportElement"
      ></div>
    </v-container>
    <v-row>
      <v-col
        v-if="
          company.DetailsVideo &&
          company.DetailsVideo[$i18n.locale] != null &&
          company.DetailsVideo[$i18n.locale] != ''
        "
        cols="12"
        md="6"
      >
        <v-container class="white" tag="section" rounded>
          <v-row align="center">
            <h4
              class="h4 container-title"
              :class="company.IsDetailsPlaylist ? 'ml-8' : 'mx-auto'"
            >
              {{ $t("intro_video") }}
            </h4>
            <v-spacer
              v-if="company.IsDetailsPlaylist && !gs.isMobile()"
            ></v-spacer>
            <v-btn
              v-if="company.IsDetailsPlaylist"
              color="primary"
              text
              :class="gs.isMobile() ? 'mx-auto' : 'mr-8'"
              @click="$router.push({ path: `playlist/${company.CompanyId}` })"
              >{{ $t("see_more") }}</v-btn
            >
          </v-row>
          <div class="mx-6">
            <iframe
              :src="`https://player.vimeo.com/video/${
                company.DetailsVideo[$i18n.locale].split(';')[0]
              }?portrait=0&byline=0&title=0`"
              width="100%"
              :height="gs.isMobile() ? '250' : '350'"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
              style="margin-bottom: 10px; margin-top: 10px"
            ></iframe>
          </div>
        </v-container>
      </v-col>
      <v-col
        v-if="
          UserType == userTypeEnum.Admin ||
          UserType == userTypeEnum.FinancialAnalyst ||
          UserType == userTypeEnum.AlphaAnalyst ||
          next_events.slice(0, 5).length > 0
        "
        cols="12"
        md="6"
      >
        <v-container class="white" tag="section" rounded>
          <h4
            v-if="
              UserType == userTypeEnum.Admin ||
              UserType == userTypeEnum.FinancialAnalyst ||
              UserType == userTypeEnum.AlphaAnalyst
            "
            class="h4 container-title"
          >
            {{ $t("calendar") }}
          </h4>
          <h4 v-else class="h4 container-title">{{ $t("next_events") }}</h4>
          <div
            v-if="
              UserType == userTypeEnum.Admin ||
              UserType == userTypeEnum.FinancialAnalyst
            "
          >
            <v-sheet>
              <v-toolbar flat color="white">
                <v-btn
                  class="mx-2"
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon small>mdi-chevron-left</v-icon>
                </v-btn>
                <v-toolbar-title>{{ get_calendar_title() }}</v-toolbar-title>
                <v-btn
                  class="mx-2"
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                >
                  <v-icon small>mdi-chevron-right</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="
                    UserType == userTypeEnum.Admin ||
                    UserType == userTypeEnum.FinancialAnalyst ||
                    UserType == userTypeEnum.AlphaAnalyst
                  "
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="open_dialog()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </v-sheet>
            <v-sheet :height="gs.isMobile() ? '360' : '430'">
              <v-calendar
                ref="calendar"
                v-model="value"
                color="primary"
                :weekdays="weekday"
                :type="type"
                :events="events"
                @click:event="showEvent"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                event-color="primary"
              >
              </v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
              >
                <v-card
                  color="grey lighten-4"
                  min-width="350px"
                  class="mt-0 mb-0"
                  flat
                >
                  <v-toolbar :color="selectedEvent.color" dark>
                    <v-btn @click="open_dialog(selectedEvent)" icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-toolbar-title
                      v-html="selectedEvent.name"
                    ></v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="delete_event(selectedEvent.Id)" icon>
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <span v-html="selectedEvent.details"></span>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="secondary" @click="selectedOpen = false"
                      >Cancel</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </div>
          <v-list v-else>
            <div v-for="item in next_events.slice(0, 5)" :key="item.Id">
              <v-hover v-slot:default="{ hover }">
                <v-list-item
                  @click="open_dialog(item)"
                  target="_blank"
                  class="file"
                  :class="{
                    'on-hover': hover,
                  }"
                  style="background-color: var(--white)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 1.2rem"
                      v-text="item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      style="font-size: 1.2rem"
                      v-text="item.details"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text
                      v-text="get_date(item.date)"
                    ></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-hover>
            </div>
          </v-list>
        </v-container>
      </v-col>
      <v-col
        v-if="
          FilesLoading ||
          files.filter((x) => gs.getLanguage(x.Language) === $i18n.locale)
            .length > 0
        "
        cols="12"
        :md="
          !UserType == userTypeEnum.Admin ||
          ((UserType == userTypeEnum.FinancialAnalyst ||
            UserType == userTypeEnum.AlphaAnalyst) &&
            company.DetailsVideo &&
            company.DetailsVideo[$i18n.locale] != null &&
            company.DetailsVideo[$i18n.locale] != '')
            ? 12
            : 6
        "
      >
        <v-container class="white" tag="section" rounded>
          <v-row justify="center" v-if="FilesLoading">
            <v-progress-circular
              indeterminate
              size="15"
              color="primary"
            ></v-progress-circular>
          </v-row>
          <div v-else>
            <h4 class="h4 container-title">
              {{ $t("files") }}
            </h4>
            <v-list>
              <div
                v-for="item in files.filter(
                  (x) => gs.getLanguage(x.Language) === $i18n.locale
                )"
                :key="item.Id"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-list-item
                    @click="download_file(item)"
                    class="file"
                    :class="{
                      'on-hover': hover,
                    }"
                    style="background-color: var(--white)"
                  >
                    <v-list-item-avatar>
                      <v-icon color="primary">mdi-folder</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 1.2rem; line-height: 1.4"
                        v-text="item.FileName"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-progress-circular
                        v-if="fileDownloading == item"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
              </div>
            </v-list>
          </div>
        </v-container>
      </v-col>
      <v-col
        v-if="
          UserType == userTypeEnum.Admin ||
          ((UserType == userTypeEnum.FinancialAnalyst ||
            UserType == userTypeEnum.AlphaAnalyst) &&
            (InvestorsLoading || investors.length > 0) &&
            admin_type == 0)
        "
        cols="12"
        :md="
          company.DetailsVideo &&
          company.DetailsVideo[$i18n.locale] != null &&
          company.DetailsVideo[$i18n.locale] != ''
            ? 6
            : 12
        "
      >
        <v-container class="white" tag="section" rounded>
          <v-row justify="center" v-if="InvestorsLoading">
            <v-progress-circular
              indeterminate
              size="15"
              color="primary"
            ></v-progress-circular>
          </v-row>
          <div v-else>
            <h4 class="h4 container-title">
              {{ $t("captable") }}
            </h4>
            <v-data-table
              :hide-default-footer="true"
              class="elevation-1 mt-3 header text-center"
              ><template v-slot:header>
                <thead>
                  <th
                    v-for="(item, i) in headers_participation"
                    :key="i"
                    class="py-4 text-center"
                  >
                    {{ item.text }}
                  </th>
                </thead>
              </template>
              <template v-slot:body>
                <tbody>
                  <tr v-for="(item, i) in get_participation()" :key="i">
                    <td>
                      {{ item.Name }}
                    </td>
                    <td>
                      {{ item.Participation }}
                    </td>
                  </tr>
                </tbody>
              </template></v-data-table
            >
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" v-if="dialog">
      <DashboardFormsAddEventModal
        v-if="
          UserType == userTypeEnum.Admin ||
          UserType == userTypeEnum.FinancialAnalyst ||
          UserType == userTypeEnum.AlphaAnalyst
        "
        :event_prop="editEvent"
        :company="company"
        @close="reset_dialog"
      ></DashboardFormsAddEventModal>
      <VisualizeEventModal
        v-else
        :event_prop="editEvent"
        @close="reset_dialog"
      ></VisualizeEventModal>
    </v-dialog>
  </div>
</template>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: rgba(0, 0, 0, 0);
}
</style>

<style scoped>
.header thead {
  font-size: 1.2rem;
  font-weight: bolder;
  color: white !important;
  background-color: var(--primary);
}

.h4,
.h5 {
  color: #006364;
}
@media only screen and (max-width: 600px) {
  .chart-select {
    max-width: 300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.chart-select {
  max-width: 500px;
  margin-top: 8px;
  margin-left: 40px;
}
.h4 {
  text-align: center;
}
.container-title {
  margin-top: 5px;
  margin-bottom: 15px;
}

.h5 {
  margin: 0;
  font-weight: bold;
  font-family: "Source Sans Pro";
}

/* section.white,
.container-component {
  -webkit-box-shadow: 1px 1px 1px 0px rgba(84, 77, 84, 1) !important;
  -moz-box-shadow: 1px 1px 1px 0px rgba(84, 77, 84, 1) !important;
  box-shadow: 1px 1px 1px 0px rgba(84, 77, 84, 1) !important;
} */

.on-hover {
  background-color: var(--dark);
  opacity: 0.5;
}
.div-info-title {
  font-size: 22px !important;
}
.v-data-table__wrapper {
  padding: 10px !important;
}
.file {
  border-bottom: grey;
  border-bottom-style: solid;
  border-bottom-width: thin;
  width: 100%;
}
.field {
  width: 225px;
  box-sizing: border-box;
  border-style: solid !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  height: 40px;
}

.company-details-header {
  margin-top: 42px;
}

.div-powerbi {
  min-width: 800px;
  height: calc(0.5625 * 64vw);
  width: 60%;
  /* Center horizontally*/
  margin: 0 auto;
}

.div-powerbi-mobile {
  height: calc(0.5625 * 150vw);
  width: 100%;
  /* Center horizontally*/
  margin: 0 auto;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import CompanyServices from "@/services/CompanyServices";
import DashboardFormsAddEventModal from "@/views/pages/company/details/events/AddEventModal";
import VisualizeEventModal from "@/views/pages/company/details/events/VisualizeEventModal";
import moment from "moment";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { LanguageTextEnum } from "@/shared/enums/LanguageEnum";
import { mapState } from "vuex";
import * as pbi from "powerbi-client";

export default {
  name: "CompanyDetails",
  components: {
    DashboardFormsAddEventModal,
    VisualizeEventModal,
    LineChart: () => import("@/components/charts/LineChart"),
    BarChart: () => import("@/components/charts/BarChart"),
  },
  data: () => ({
    gs: new GeneralServices(),
    cs: new CompanyServices(),
    chartdata: {},
    editEvent: null,
    value: "",
    apiService: new ApiService(),
    add_investidor_component: false,
    charts: [],
    fileDownloading: null,
    selected_chart: {
      data: {
        labels: ["teste"],
        datasets: [
          {
            backgroundColor: "#01D281",
            data: [2],
          },
        ],
      },
      name: "Teste",
      index: 0,
    },
    loading: false,
    dialog: false,
    type: "month",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    events: [],
    next_events: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    isBarChart: false,
    selectedChartType: [
      { text: "Bar-Graphic", value: true },
      { text: "Line-Graphic", value: false },
    ],
    initialDate: "",
    finalDate: "",
    DefaultGraph: 1,
    userTypeEnum: UserTypeEnum,
  }),
  mounted() {
    this.reload_kpi_charts();
    this.loadPowerBiReport();
  },
  async created() {
    if (
      this.company.DetailsVideo != null &&
      this.isJson(this.company.DetailsVideo)
    ) {
      this.company.DetailsVideo = JSON.parse(this.company.DetailsVideo);
    }
  },
  computed: {
    ...mapState(["lang"]),
    options() {
      return {};
    },
    headers_participation() {
      return [
        {
          text: this.$t("name"),
          value: "Name",
        },
        {
          text: this.$t("participation"),
          value: "Participation",
        },
      ];
    },
    headers_files() {
      return [
        {
          text: this.$t("name"),
          value: "Name",
        },
      ];
    },
  },

  methods: {
    getDefaultGraph() {
      let defGraph =
        this.company.CompanyKpis[this.selected_chart.index].DefaultGraph;
      if (defGraph || defGraph === 0) this.DefaultGraph = defGraph;
    },
    translateChartSelected(item) {
      return this.$t(item.text);
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    fill_kpi_charts() {
      var i, j, date;
      var chart;

      this.charts = [];
      for (i = 0; i < this.company.CompanyKpis.length; i++) {
        let scales = {};
        let history = this.company.CompanyKpis[i].KpiValueHistory;
        if (
          this.company.CompanyKpis[i].YAxisStart !== null ||
          this.company.CompanyKpis[i].YAxisEnd !== null
        ) {
          scales.yAxes = [
            {
              display: true,
              ticks: {},
            },
          ];
          if (this.company.CompanyKpis[i].YAxisStart !== null) {
            scales.yAxes[0].ticks.min = this.company.CompanyKpis[i].YAxisStart;
          }
          if (this.company.CompanyKpis[i].YAxisEnd !== null) {
            scales.yAxes[0].ticks.max = this.company.CompanyKpis[i].YAxisEnd;
          }
        }
        if (
          this.company.CompanyKpis[i].XAxisStart != null ||
          this.company.CompanyKpis[i].XAxisEnd != null
        ) {
          if (this.company.CompanyKpis[i].XAxisStart != null) {
            history = history.filter(
              (x) =>
                moment(x.Date) >= moment(this.company.CompanyKpis[i].XAxisStart)
            );
          }
          if (this.company.CompanyKpis[i].XAxisEnd != null) {
            history = history.filter(
              (x) =>
                moment(x.Date) <= moment(this.company.CompanyKpis[i].XAxisEnd)
            );
          }
        }
        chart = {
          data: {
            labels: [],
            datasets: [
              {
                backgroundColor: "#01D281",
                borderColor: "#01D281",
                data: [],
              },
            ],
          },
          options: {
            elements: {
              line: {
                tension: 0,
              },
            },
            legend: {
              display: false,
            },
            scales: scales,
            maintainAspectRatios: false,
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              enabled: true,
              callbacks: {
                label: (tooltipItems, data) => {
                  return `${parseFloat(tooltipItems.yLabel)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
                },
              },
            },
          },
        };
        for (j = 0; j < history.length; j++) {
          if (history[j].Value != 0) {
            date = moment(history[j].Date);
            chart.data.labels.push(`${date.format("MM/YY")}`);
            chart.data.datasets[0].data.push(history[j].Value);
          }
        }

        this.charts.push({
          data: chart.data,
          options: chart.options,
          name: this.getChartName(i),
          index: this.charts.length + 1,
        });
      }
      if (this.charts.length > 0) {
        this.selected_chart = this.charts[this.selected_chart.index];
      }

      this.getDefaultGraph();
    },

    getChartName(index) {
      const parsedReportName = JSON.parse(
        this.company.CompanyKpis[index].ReportName
      );
      return `${
        this.lang === LanguageTextEnum.PT
          ? parsedReportName.pt
          : parsedReportName.en
      } (${this.company.CompanyKpis[index].Unit})`;
    },
    get_date(date) {
      if (date == null || date == undefined) {
        return "";
      } else {
        var moment_date = moment(date).zone("+0000");
        if (moment_date.format("HH:mm") == "00:00") {
          return moment_date.format("DD/MM/YYYY");
        } else {
          return moment_date.format("DD/MM/YYYY HH:mm");
        }
      }
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    get_stock_value_average() {
      let val = 0;
      if (
        !this.company.StockValueHistory ||
        this.company.StockValueHistory.length == 0
      ) {
        return;
      }
      this.company.StockValueHistory[0].StockTypes.forEach((st) => {
        val += st.Value;
      });
      if (val > 0) {
        return this.gs.format_to_currency(
          val / this.company.StockValueHistory[0].StockTypes.length
        );
      } else {
        return this.gs.format_to_currency(0.0);
      }
    },
    parse_events: function (events) {
      this.events = [];
      this.next_events = [];
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var current_date = yyyy + "-" + mm + "-" + dd + "T00:00:00Z";
      events.forEach((element) => {
        var start_time = element.Date;
        var moment_date = moment(start_time);
        var timed = true,
          date = moment_date.format("YYYY-MM-DD"),
          time = moment_date.format("HH:mm:ss");

        if (time == "00:00:00") {
          timed = false;
        }

        date = new Date(
          parseInt(moment_date.year),
          parseInt(moment_date.month) - 1,
          parseInt(moment_date.day)
        );

        this.events.push({
          Id: element.Id,
          name: element.Name,
          details: element.Details,
          link: element.Link,
          date: start_time + "Z",
          start: moment(start_time).format("YYYY-MM-DD HH:mm"),
          end: moment(start_time).format("YYYY-MM-DD HH:mm"),
          color: "primary",
          timed: timed,
        });

        var isAfter = moment(this.events[this.events.length - 1].date).isAfter(
          current_date
        );
        if (isAfter) {
          this.next_events.push(this.events[this.events.length - 1]);
        }
      });
    },
    open_dialog: function (event) {
      this.editEvent = event;
      this.dialog = true;
    },
    download_file: async function (file) {
      this.fileDownloading = file;
      try {
        await this.cs.download_company_file(file.Id);
      } catch (e) {
        this.$toast.error(this.$t("error_occoured"));
      }
      this.fileDownloading = null;
    },
    reset_dialog(event) {
      this.editEvent = null;
      this.dialog = false;
      var index = this.company.CompanyEvents.map((x) => x.Id).indexOf(event.Id);
      if (index == -1) {
        this.company.CompanyEvents.push(event);
      } else {
        this.company.CompanyEvents[index] = event;
      }
      this.parse_events(this.company.CompanyEvents);
    },
    get_calendar_title: function () {
      if (this.$refs.calendar != null && this.$refs.calendar != undefined) {
        if (
          this.$refs.calendar.title != null &&
          this.$refs.calendar.title != undefined
        ) {
          return this.$refs.calendar.title;
        } else {
          return this.$t("calendar");
        }
      } else {
        return this.$t("calendar");
      }
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    get_participation: function () {
      if (this.investors) {
        var array = [];
        var investment_total = this.get_investment_total();
        this.investors.forEach((element) => {
          array.push({
            Name: element.InvestorName,
            Participation: (
              (100 * this.$parent.get_total_invested(element)) /
              investment_total
            )
              .toFixed(2)
              .toString()
              .concat("%"),
          });
        });
        return array;
      }
    },
    get_length: function (array) {
      if (!array) {
        return 0;
      } else {
        return array.length;
      }
    },
    get_stock_value: function () {
      return this.gs.format_to_currency(
        this.company.StockValueHistory[
          this.company.StockValueHistory.length - 1
        ].StockValue
      );
    },
    get_investment_total: function () {
      var total = 0;
      this.investors.forEach((element) => {
        total += this.$parent.get_total_invested(element);
      });
      return total;
    },
    reload_kpi_charts() {
      if (this.company.CompanyKpis && this.company.CompanyKpis.length > 0) {
        this.fill_kpi_charts();
      }
    },

    loadPowerBiReport() {
      try {
        let embedConfiguration = {
          type: "report",
          id: this.company.Pbi.Id, // "1e2fe97e-c8f5-4198-8651-3272c5ab6a81",
          embedUrl: this.company.Pbi.EmbedUrl, // "https://app.powerbi.com/reportEmbed?reportId=1e2fe97e-c8f5-4198-8651-3272c5ab6a81&groupId=2e9804ec-26d8-4f72-82ea-e1967cd6a56c&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7InVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d",
          tokenType: pbi.models.TokenType.Embed,
          accessToken: this.company.Pbi.Token, // "H4sIAAAAAAAEAC2UxxKr2AFE_-VtcRU5uWoWXAEiZxCwIyMQ6JKDy_9uuWb2vTp9uv_zx8muzzcr__z7T29ddnlYGwcCx8CP4VmgU6XCJUba4ew5DbQFKRkprw6nU-BXOFfW0iOiZKG6fZGexzdhoF-e2Fb2o7DqEjcM8UQV6aPUsrgkD23BLTrSvBfKI4aSUvLUBewNry6Q58ai1FDyEIIXmjzQVd95V5xb93lRdfj1lJ2vsFVZvbJYPPYz7X7fPUCcTaqeY0T1adbF79HrF_USwuLW_LYOSOgJ_Kt7sK81gRAL9cNCrBUGDncEB8Mo1hIhgI7fKAsj5tMheru4z4Gj8Ua-LVYrTG3S8vCwrCDIppebRuvdcPgd8r383nKsiwyFit33ZMljBOPUPgpX3tJoKHB2xpcxNrScLtGyj58Xt251kWM5vKVDjlPzmKdGobC3W1E7bRj6FMxzYDTPaFTRErsiUkpQ_9YvkOX2lXfEXiOCenmPilCYaeNjQWzPPpHVV87ZS60En_SygCZ-QXn208vn29v57OoiKK8UKLIVSU76iZ47478tI838iWgDW5NE3z25yvwua7-8_Uf4eRRnBGgtrJUzaoC4J7q65_O64QRBz14ICK5SE6zt8IdLPLZerxNI3oC6nA32x_1asuR5F5qkt_qwZemn3FFsZYquWJlWzU20gXQC3ymVccjNttf83DNRczOMm32EKT_3CXV73qAgLUoGNVIU4TfeDJi_jre7P0-WNHykH4Nda-kfUgtZp-LlJjSQ96BimTlUzL4GI7VQvnd-084yCP7TlC2jDG4m7cqYk2oyKMZPtg45rKgTg6d7tLDQ87i6c2NP6YpiRawwEQRfSb-I8RCIPKL0HghGNVz9veQH3WqPtum3HfA2441SMdsDWs5yNM2Ewayct9voC9wlq7VNecJhd__668-__jzmC65fvbp-M9MlenkFOgdUQjnLcS5Gqb7IPdnqoU2PmF8ZU1PKumPQluD8vcnvYYtj7WZqeH9nPlVF8jZNrSg2lDt3GFzc9HVaWtv8sCjCq_Ap3hkPZc-__hCMJe7M_JTIFA8lkrSe9FQmK3yK1TGyqMGWZlIThCkXhOx3fHs2efZJ2-MwoOLY9IADyn9FzEKRgY5Rqo-txcJRafwrL13ogelpYL7EiaAC1p30MDpoP9BihauyXIpkws8ZwOUNMJbszTWsMthHcx0roSWpBbMq_wCBuoRmSMRIMhXcffY1gUzZ2kWryX2ZVABcqYiP91YeMn8w910_kEtZ4J5nHIZs3m9tXb8Dpe_-wXzBtprV6EfZtb_3yTYmpAIj7Vr4tGkm-Dvlv5sxW7e5-sUy_U2ndIMOwY3FpDomLQeEST1afcvbyZzfEh4zp8yyL4JFjCp84EcmYAPdLYgLohaV1ZwHjta1lYFdiiWQgYwrVrJ-fBJEqry82OC3XfPK8zx69pfbv87BG9OUgD3CnjDYrML42ZJ1W2aRC8Kl3a9SUGOzdRGPRhQxS-Ra6b4Wz-DBHRyv6RDKBwkyoRykUVmyE3S_cwFMiJnvn6y01sXGjD-8xZUdlOB_Xxz5CXTFn41KHdt3UJpCAQGTU-b5tsUlcGDHOoJrZxCfUDCNXjfEgsp3j6P0VH370s_Bqa6Psua9tPf5cY5ZLRCSL3yttXW7LYnsWcxVWqHthZ7Un5rL_zH_939lRbFYWgYAAA==.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJleHAiOjE2OTU3Mzc3MzUsImFsbG93QWNjZXNzT3ZlclB1YmxpY0ludGVybmV0Ijp0cnVlfQ==",
          settings: {
            filterPaneEnabled: false,
            commands: [
              {
                exportData: {
                  displayOption: pbi.models.CommandDisplayOption.Hidden,
                },
              },
            ],
          },
        };

        let powerbi = new pbi.service.Service(
          pbi.factories.hpmFactory,
          pbi.factories.wpmpFactory,
          pbi.factories.routerFactory
        );

        let embedContainer = document.getElementById("ReportElement");
        var visual = powerbi.embed(embedContainer, embedConfiguration);
      } catch (e) {}
    },

    delete_event: function (id) {
      var index = this.company.CompanyEvents.map((x) => x.Id).indexOf(id);
      this.company.CompanyEvents.splice(index, 1);
      this.parse_events(this.company.CompanyEvents);
      this.selectedOpen = false;
      this.apiService
        .deleteRequest("companyevent/delete/".concat(id))
        .then((resp) => {})
        .catch((error) => {});
    },
  },
  props: {
    admin_type: Number,
    UserTotalInvested: {
      type: String,
      default: () => "0",
    },
    UserType: Number,
    company: Object,
    investors: Array,
    files: Array,
    InvestorsLoading: Array,
    FilesLoading: Array,
    StockHistoryLoading: Array,
  },
  watch: {
    lang() {
      this.fill_kpi_charts();
    },
    "company.CompanyEvents"() {
      if (this.company.CompanyEvents && this.company.CompanyEvents.length > 0) {
        this.parse_events(this.company.CompanyEvents);
      }
    },
    "company.CompanyKpis"() {
      this.reload_kpi_charts();
    },
  },
};
</script>
<style>
.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: var(--primary) !important;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #7733ff !important;
}
.ct-series-c .ct-bar,
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-slice-donut {
  stroke: #197dff !important;
}
.ct-series-d .ct-bar,
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-slice-donut {
  stroke: #ffff00 !important;
}
.ct-series-e .ct-bar,
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-slice-donut {
  stroke: #ff99ff !important;
}
.ct-series-f .ct-bar,
.ct-series-f .ct-line,
.ct-series-f .ct-point,
.ct-series-f .ct-slice-donut {
  stroke: #006622 !important;
}
.ct-series-g .ct-bar,
.ct-series-g .ct-line,
.ct-series-g .ct-point,
.ct-series-g .ct-slice-donut {
  stroke: #ff0000 !important;
}
.ct-series-h .ct-bar,
.ct-series-h .ct-line,
.ct-series-h .ct-point,
.ct-series-h .ct-slice-donut {
  stroke: #ff9900 !important;
}
.ct-series-i .ct-bar,
.ct-series-i .ct-line,
.ct-series-i .ct-point,
.ct-series-i .ct-slice-donut {
  stroke: var(--dark) !important;
}
</style>
