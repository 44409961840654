import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"company-details-header"},[_c(VCol,{attrs:{"cols":"12","md":_vm.UserType == _vm.userTypeEnum.Admin ||
        _vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
        _vm.UserType == _vm.userTypeEnum.AlphaAnalyst
          ? 4
          : 6}},[_c(VContainer,{staticClass:"white",attrs:{"tag":"section","rounded":""}},[_c('div',{staticClass:"ml-4"},[(
              _vm.UserType == _vm.userTypeEnum.Admin ||
              _vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
              _vm.UserType == _vm.userTypeEnum.AlphaAnalyst
            )?_c('div',[_c(VRow,[_c('h4',{staticClass:"h4 div-info-title",staticStyle:{"color":"var(--primary)"}},[_vm._v(" "+_vm._s(_vm.get_length(_vm.investors))+" ")])]),_c(VRow,[_c('h5',{staticClass:"h5 div-info-title"},[_vm._v(_vm._s(_vm.$t("stakeholders")))])])],1):_vm._e()])])],1)],1),(!_vm.company.Pbi && _vm.charts.length > 0)?_c(VContainer,{staticClass:"white my-3",attrs:{"tag":"section","fluid":"","rounded":""}},[_c(VRow,[_c(VSelect,{staticClass:"chart-select mx-auto",attrs:{"items":_vm.charts,"item-text":"name","item-value":"index","dense":"","outlined":"","return-object":""},on:{"change":_vm.getDefaultGraph},model:{value:(_vm.selected_chart),callback:function ($$v) {_vm.selected_chart=$$v},expression:"selected_chart"}})],1),_c('div',[(!_vm.loading && _vm.DefaultGraph == 1)?_c('line-chart',{attrs:{"chartData":JSON.parse(JSON.stringify(_vm.selected_chart.data)),"options":_vm.selected_chart.options}}):(!_vm.loading && _vm.DefaultGraph == 0)?_c('bar-chart',{attrs:{"chartData":JSON.parse(JSON.stringify(_vm.selected_chart.data)),"options":_vm.selected_chart.options}}):_vm._e()],1)],1):(_vm.company.Pbi)?_c(VContainer,{staticClass:"white my-3",attrs:{"tag":"section","fluid":"","rounded":""}},[_c('div',{class:_vm.gs.isMobile() ? 'div-powerbi-mobile' : 'div-powerbi',attrs:{"id":"ReportElement"}})]):_vm._e(),_c(VRow,[(
        _vm.company.DetailsVideo &&
        _vm.company.DetailsVideo[_vm.$i18n.locale] != null &&
        _vm.company.DetailsVideo[_vm.$i18n.locale] != ''
      )?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VContainer,{staticClass:"white",attrs:{"tag":"section","rounded":""}},[_c(VRow,{attrs:{"align":"center"}},[_c('h4',{staticClass:"h4 container-title",class:_vm.company.IsDetailsPlaylist ? 'ml-8' : 'mx-auto'},[_vm._v(" "+_vm._s(_vm.$t("intro_video"))+" ")]),(_vm.company.IsDetailsPlaylist && !_vm.gs.isMobile())?_c(VSpacer):_vm._e(),(_vm.company.IsDetailsPlaylist)?_c(VBtn,{class:_vm.gs.isMobile() ? 'mx-auto' : 'mr-8',attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$router.push({ path: ("playlist/" + (_vm.company.CompanyId)) })}}},[_vm._v(_vm._s(_vm.$t("see_more")))]):_vm._e()],1),_c('div',{staticClass:"mx-6"},[_c('iframe',{staticStyle:{"margin-bottom":"10px","margin-top":"10px"},attrs:{"src":("https://player.vimeo.com/video/" + (_vm.company.DetailsVideo[_vm.$i18n.locale].split(';')[0]) + "?portrait=0&byline=0&title=0"),"width":"100%","height":_vm.gs.isMobile() ? '250' : '350',"frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})])],1)],1):_vm._e(),(
        _vm.UserType == _vm.userTypeEnum.Admin ||
        _vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
        _vm.UserType == _vm.userTypeEnum.AlphaAnalyst ||
        _vm.next_events.slice(0, 5).length > 0
      )?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VContainer,{staticClass:"white",attrs:{"tag":"section","rounded":""}},[(
            _vm.UserType == _vm.userTypeEnum.Admin ||
            _vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
            _vm.UserType == _vm.userTypeEnum.AlphaAnalyst
          )?_c('h4',{staticClass:"h4 container-title"},[_vm._v(" "+_vm._s(_vm.$t("calendar"))+" ")]):_c('h4',{staticClass:"h4 container-title"},[_vm._v(_vm._s(_vm.$t("next_events")))]),(
            _vm.UserType == _vm.userTypeEnum.Admin ||
            _vm.UserType == _vm.userTypeEnum.FinancialAnalyst
          )?_c('div',[_c(VSheet,[_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.get_calendar_title()))]),_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1),_c(VSpacer),(
                  _vm.UserType == _vm.userTypeEnum.Admin ||
                  _vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
                  _vm.UserType == _vm.userTypeEnum.AlphaAnalyst
                )?_c(VBtn,{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":function($event){return _vm.open_dialog()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c(VSheet,{attrs:{"height":_vm.gs.isMobile() ? '360' : '430'}},[_c(VCalendar,{ref:"calendar",attrs:{"color":"primary","weekdays":_vm.weekday,"type":_vm.type,"events":_vm.events,"event-overlap-mode":_vm.mode,"event-overlap-threshold":30,"event-color":"primary"},on:{"click:event":_vm.showEvent},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c(VCard,{staticClass:"mt-0 mb-0",attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c(VToolbar,{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.open_dialog(_vm.selectedEvent)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),_c(VToolbarTitle,{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.delete_event(_vm.selectedEvent.Id)}}},[_c(VIcon,[_vm._v("mdi-trash-can")])],1)],1),_c(VCardText,[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.details)}})]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1):_c(VList,_vm._l((_vm.next_events.slice(0, 5)),function(item){return _c('div',{key:item.Id},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c(VListItem,{staticClass:"file",class:{
                  'on-hover': hover,
                },staticStyle:{"background-color":"var(--white)"},attrs:{"target":"_blank"},on:{"click":function($event){return _vm.open_dialog(item)}}},[_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-size":"1.2rem"},domProps:{"textContent":_vm._s(item.name)}}),_c(VListItemSubtitle,{staticStyle:{"font-size":"1.2rem"},domProps:{"textContent":_vm._s(item.details)}})],1),_c(VListItemAction,[_c(VListItemActionText,{domProps:{"textContent":_vm._s(_vm.get_date(item.date))}})],1)],1)]}}],null,true)})],1)}),0)],1)],1):_vm._e(),(
        _vm.FilesLoading ||
        _vm.files.filter(function (x) { return _vm.gs.getLanguage(x.Language) === _vm.$i18n.locale; })
          .length > 0
      )?_c(VCol,{attrs:{"cols":"12","md":!_vm.UserType == _vm.userTypeEnum.Admin ||
        ((_vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
          _vm.UserType == _vm.userTypeEnum.AlphaAnalyst) &&
          _vm.company.DetailsVideo &&
          _vm.company.DetailsVideo[_vm.$i18n.locale] != null &&
          _vm.company.DetailsVideo[_vm.$i18n.locale] != '')
          ? 12
          : 6}},[_c(VContainer,{staticClass:"white",attrs:{"tag":"section","rounded":""}},[(_vm.FilesLoading)?_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"15","color":"primary"}})],1):_c('div',[_c('h4',{staticClass:"h4 container-title"},[_vm._v(" "+_vm._s(_vm.$t("files"))+" ")]),_c(VList,_vm._l((_vm.files.filter(
                function (x) { return _vm.gs.getLanguage(x.Language) === _vm.$i18n.locale; }
              )),function(item){return _c('div',{key:item.Id},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c(VListItem,{staticClass:"file",class:{
                    'on-hover': hover,
                  },staticStyle:{"background-color":"var(--white)"},on:{"click":function($event){return _vm.download_file(item)}}},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-folder")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticStyle:{"font-size":"1.2rem","line-height":"1.4"},domProps:{"textContent":_vm._s(item.FileName)}})],1),_c(VListItemAction,[(_vm.fileDownloading == item)?_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1)],1)]}}],null,true)})],1)}),0)],1)],1)],1):_vm._e(),(
        _vm.UserType == _vm.userTypeEnum.Admin ||
        ((_vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
          _vm.UserType == _vm.userTypeEnum.AlphaAnalyst) &&
          (_vm.InvestorsLoading || _vm.investors.length > 0) &&
          _vm.admin_type == 0)
      )?_c(VCol,{attrs:{"cols":"12","md":_vm.company.DetailsVideo &&
        _vm.company.DetailsVideo[_vm.$i18n.locale] != null &&
        _vm.company.DetailsVideo[_vm.$i18n.locale] != ''
          ? 6
          : 12}},[_c(VContainer,{staticClass:"white",attrs:{"tag":"section","rounded":""}},[(_vm.InvestorsLoading)?_c(VRow,{attrs:{"justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"15","color":"primary"}})],1):_c('div',[_c('h4',{staticClass:"h4 container-title"},[_vm._v(" "+_vm._s(_vm.$t("captable"))+" ")]),_c(VDataTable,{staticClass:"elevation-1 mt-3 header text-center",attrs:{"hide-default-footer":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',_vm._l((_vm.headers_participation),function(item,i){return _c('th',{key:i,staticClass:"py-4 text-center"},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]},proxy:true},{key:"body",fn:function(){return [_c('tbody',_vm._l((_vm.get_participation()),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(item.Name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.Participation)+" ")])])}),0)]},proxy:true}],null,false,2319662053)})],1)],1)],1):_vm._e()],1),(_vm.dialog)?_c(VDialog,{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(
        _vm.UserType == _vm.userTypeEnum.Admin ||
        _vm.UserType == _vm.userTypeEnum.FinancialAnalyst ||
        _vm.UserType == _vm.userTypeEnum.AlphaAnalyst
      )?_c('DashboardFormsAddEventModal',{attrs:{"event_prop":_vm.editEvent,"company":_vm.company},on:{"close":_vm.reset_dialog}}):_c('VisualizeEventModal',{attrs:{"event_prop":_vm.editEvent},on:{"close":_vm.reset_dialog}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }