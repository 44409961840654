<template>
  <v-container id="add-investor-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title class="mb-6">
        <h4 class="dxa_modal_title h4">{{ page_title() }}</h4>
      </v-card-title>
      <v-card-text>
        <p>
          <b style="margin-right: 4px">{{ $t("name") }}:</b>{{ event.name }}
        </p>
        <p>
          <b style="margin-right: 4px">{{ $t("date") }}:</b>{{ date }}
        </p>
        <p>
          <b style="margin-right: 4px">{{ $t("event_link") }}:</b
          ><a :href="event.link" target="_blank">{{ event.link }}</a>
        </p>
        <p>
          <b style="margin-right: 4px">{{ $t("details") }}:</b
          >{{ event.details }}
        </p>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="$emit('close')">
            {{ $t("go_back") }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import moment from "moment";

export default {
  name: "VisualizeEventModal",
  data: () => ({
    apiService: new ApiService(),
    event: {},
    date: null,
  }),
  props: {
    event_prop: Object,
  },
  async created() {
    this.event = this.event_prop;
    this.date = this.get_date_string();
  },
  computed: {},
  methods: {
    get_date_string() {
      var moment_date = moment(this.event.date).zone("+0000");
      if (moment_date.format("HH:mm") == "00:00") {
        return moment_date.format("DD/MM/YYYY");
      } else {
        return moment_date.format("DD/MM/YYYY HH:mm");
      }
    },
    page_title: function () {
      return this.event.name;
    },
  },
};
</script>
